<template>
  <div class="msp-grafico-lap">
        <v-btn-toggle mandatory v-model="currentGraph">
            <v-btn x-small v-for="(grafico, index) in attivita.laps.grafico" :key="index">
            {{grafico.label}}
            </v-btn>
        </v-btn-toggle>
        <msp-bar-chart
        :config="config"
        ></msp-bar-chart>
  </div>
</template>

<script>


import {configLaps as config} from "@mixins/configBarChart.js"
import mspBarChart from "./MspBarChart.vue"
export default {
    name: 'mspGraficoLap',
  components: {
      mspBarChart,
  },
    data() {
        return {
            currentGraph: 0,
            config: null,
        }
    },
    props: [
        'attivita',
    ],
    watch: {
        currentGraph: function () {
            this.makeGraph();
        }
    },
    mounted() {
        this.makeGraph();
    },
    methods: {
      makeGraph: function () {

            if (!(
                this.attivita.laps
                && this.attivita.laps.grafico
                )) {
                return;
            }
            const laps = this.attivita.laps.grafico[this.currentGraph];
            if(!laps) {
                return;
            }
            let tmp_data = laps.values;
            let label = laps.label;
            //{"label":"Z5","value":3,"displayValue":3,"color":"#F92011","toolText":"<H6>Z5<\/H6>Tempo tot.: 00:02:52<BR><STRONG>4.1 %<\/STRONG>"},
            let values=[];
            let labels=[];
            let tooltips=[];
            let backgroundColors=[];
            tmp_data.forEach((el,i)=>{
                labels.push("#"+i);
                values.push(el.value);
                tooltips.push(el.toolArray);
                backgroundColors.push(el.color);
            });
            const data = {
              labels: labels,
              datasets: [
                {
                  label: label,
                  data: values,
                  backgroundColor: backgroundColors,
                  tooltips: tooltips,
                },
              ]
            };
            config.data = data;
            config.options = {
                ...config.options,
                scales: {
                    ...config.options.scales,
                    x: {
                        display: false,
                    }
                }
            };
            this.config = Object.assign({}, config);
        },
    },
}
</script>
