import Color from 'color';
//COLORS:
//require color
const color = {};
color.mix = function (a, b, amount)  {
  let col1 =  Color(a);
  let col = col1.mix(Color(b), amount/100);
  return col.hex();
}

color.color = Color;

export { color as default };
