<template>
    <div class="attivita-riepilogo-cp-container" :class="classRiepilogoCp">
        <div class="msp-segment">
            <label v-for="set_item in selectRiepilogoCp.options" :key=set_item.id class="msp-segment__item">
                <input class="selettori" type="radio" v-model="selectRiepilogoCp.selected" :value="set_item.id">
                <span class="msp-segment__label" v-text="set_item.label"></span>
            </label>
        </div>
        <template v-if="riepilogo" >

            <div class='attivita-riepilogo-cp attivita-riepilogo-cp-head'>
                <div v-for="header in riepilogo.headers" :key="header.field" :class="classHeader(header)">{{header.titolo}} </div>
            </div>



            <div v-for="row in riepilogo.rows" :key="row.segmento.id" class='attivita-riepilogo-cp interactive' @click="openModal('record')">
                <div class='arp-time'><strong>{{row.segmento.segmentoString}}</strong></div>

                <div class='contents' v-for="(cell,index) in row.cells" :key="index">

                    <div v-if="cell.valore" :class='cell.classeCol +" "+ cell.classeValore'><strong> {{cell.valore}}</strong> </div>
<div class="arp-coccarda" v-if='cell.classeCol==="arp-attuale-col"' v-html="renderBest(row,cell)"></div>

                    <div v-if="cell.data" :class='cell.classeCol + " arp-data"'> {{cell.data}} </div>
                    <div v-if="cell.classePercentuale" :style="'--color:' + cell.colore" :class='cell.classeCol + " " + cell.classePercentuale'> <span v-text="cell.variazione" /> </div>

                    <div v-if="cell.dettaglio" :class='cell.classeCol + " " + cell.classeDettaglio'><strong> {{cell.dettaglio}}</strong></div>

                </div>
            </div>
        </template>
    </div>
</template>

<script>
// TODO: confrontare CP Oggi con i record fino al giorno precedente
import  {_api, URLparams} from "@src/js/msp/api.js";
import env from "@mixins/environment.js";
import _recordSegmenti from "@cached/record_segmento.json";
import _record from "@mixins/record-segmenti.js";

const component = {
  props: [
      "attivita_id",
      "token",
  ],
  computed: {
        classRiepilogoCp: function () {
            let selected = this.selectRiepilogoCp.options.find(
                (el)=>{
                    return el.id === this.selectRiepilogoCp.selected;
                });
            if (!selected) return "";
            return selected.class;
        },
  },
  mixins: [
      env,
  ],
  data: function() {
    return {
        riepilogo: null,
        segmenti: _recordSegmenti,
        selectRiepilogoCp: {
            selected: 0,
            options: [
                {
                    id: 0,
                    label: 'Best 3 Mesi',
                    class: 'best',
                },
                {
                    id: 1,
                    label: 'Best Anno',
                    class: 'best-anno',
                },
                {
                    id: 2,
                    label: 'Best All Time',
                    class: 'best-all-time',
                },
                {
                    id: 3,
                    label: 'Tutti',
                    class: 'tutti',
                },
            ]
        },
    }
  },
  watch: {
      attivita_id: function () {
          this.init();
      },
  },
  mounted: function() {
      this.init();
  },
  methods: {
      renderBest: function (row, cell) {
          void(cell);
          if (!(this.riepilogo && this.riepilogo.bests && this.riepilogo.bests.length)) return "";
          let best = this.riepilogo.bests.find(el=>el.rs_id == row.segmento.id);
          if (!best) {
              return "";
          }

          // best all time
          const config = _record.configBest;
          const color = config[best.posizione-1].color;
          const style = `--color: ${color}`;
          return `<i style='${style}' class='icon-best msp-icon-award' />`;
      },

      classHeader: function (header) {
          return `arp-head ${header.classeCol} ${header.classeHead}`;
      },

      init: function() {
          this.loadTabella();
      },
      makeRiepilogo: function (data) {
          const riepilogo = {
              ...data,
          };
          return riepilogo;
      },
        loadTabella: function () {
            if (!this.attivita_id) {
                return;
            }
            let data = {
                riepilogo_cp: 1,
                id: this.attivita_id,
            }
            let params = URLparams(data);
            let url = this.api_base_url+`/api/v2/attivita.php?${params}`;

            return new Promise((resolve,reject)=>{
                _api(url,'get',null,this.token)
                    .then((result)=>{

                        if (result.success) {
                            this.riepilogo = this.makeRiepilogo(result.data);
                            resolve(result.data);
                        } else {
                            this.listaSegmenti = [];
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });
        },
  },
}
export default component;
</script>

<style lang="scss">
.attivita-riepilogo-cp-container {
    --fixed-cols: 5em 50px;

    --display-best: block;
    --display-best-anno: block;
    --display-best-all-time: block;
    --grid-template-rows: repeat(4, auto);
    --grid-template-columns: var(--fixed-cols) repeat(3,1fr);
    --col-coccarda: 2;
    --col-best: 3;
    --col-best-anno: 4;
    --col-best-all-time: 5;
    --display-dettaglio: none;

    
    &.best,
    &.best-anno,
    &.best-all-time {
        --display-best: none;
        --display-best-anno: none;
        --display-best-all-time: none;
        --grid-template-rows: repeat(2, auto);
        --grid-template-columns: var(--fixed-cols) repeat(2,1fr);
        --col-best: 3;
        --col-best-anno: 3;
        --col-best-all-time: 3;
        .arp-percentuale {
            --col: 4;
            --row: 2;
        }
    }
    .arp-coccarda {
        --col: var(--col-coccarda);
        --row: 1/-1;
        grid-row: var(--row);
    }

    &.best {
        --display-best: block;
    }
    &.best-anno {
        --display-best-anno: block;
    }
    &.best-all-time {
        --display-best-all-time: block;
    }


    .arp-attuale-col.arp-dettaglio,
    &.best .arp-best-col,
    &.best-anno .arp-best-anno-col,
    &.best-all-time .arp-best-all-time-col {
        --display-dettaglio: block;
    }
}

.attivita-riepilogo-cp {
    text-align: left;
    margin: 4px 0;
    width: 100%;
    font-size: 0.8em;
    padding:5px;
    border:1px solid #ccc !important;
    background-color:#f6f6f6 !important;
    display: grid;
    grid-template-rows: var(--grid-template-rows);
    grid-template-columns: var(--grid-template-columns);
    gap: 0px 4px;
    --col: 1;

    .icon-best {
        --color: var(--col-grigio-chiaro);
        color: var(--color);
        font-size: 30px;
    }
    &.attivita-riepilogo-cp-head {
        grid-template-rows: 1fr;
    }

    .arp-time {
        grid-row: 1;
        grid-column: 1;
        font-size: 1.2em;
    }

    .arp-head {
        --row: 1;
        grid-column: var(--col);
        grid-row: var(--row);
    }

    .arp-valore {
        --row: 2;
        grid-column: var(--col);
        grid-row: var(--row);
    }

    .arp-percentuale {
        color: var(--color);
        --row: 3;
        grid-column: var(--col);
        grid-row: var(--row);
    }

    .arp-data {
        --row: 1;
        grid-column: var(--col);
        grid-row: var(--row);
    }

    .arp-valore {
        font-weight: bolder;
    }
    .arp-peso {
        font-style: italic;
        font-size: 0.9em;
    }

    .arp-best-col {
        --col: var(--col-best);
        display: var(--display-best);
    }

    .arp-best-anno-col {
        --col: var(--col-best-anno);
        display: var(--display-best-anno);
    }

    .arp-best-all-time-col {
        --col: var(--col-best-all-time);
        display: var(--display-best-all-time);
    }

    .arp-dettaglio {
        display: var(--display-dettaglio);
    }

    .arp-attuale-col {
        --col: 1;
    }

    .contents {
        display: contents;
    }
}
</style>
