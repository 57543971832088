import {api, URLparams} from '../../mobile/src/mixins/api.js';
import CONFIG from '../msp/config.js';
import funzioni from '../msp/funzioni.js';
export const messages = {
    "MSP_NOAUTH_GARMIN": "Non hai collegato Garmin. Aggiungi la connessione.",
    "MSP_NOALLENAMENTO": "Allenamento insesistente.",
    "MSP_NOZONE"      : "Non posso esportare l'allenamento perché non hai nessun test attivo.",
    "MSP_NODATA_GARMIN": "L'esportazione ha generato un file vuoto.",
    "MSP_ERROR": "Si è verificato un errore inaspettato.",
};
export const availableErrors = [
    ["NOAUTH", "MSP_NOAUTH_GARMIN"],
    ["NOALLENAMENTO", "MSP_NOALLENAMENTO"],
    ["NOZONE", "MSP_NOZONE"],
    ["EMPTYJSON", "MSP_NODATA_GARMIN"],
];

export function getErrorMessage (errors) {
    const defaultError = "MSP_ERROR";
    let indexError = availableErrors.map(el=>el[0]).findIndex((el)=>(errors.indexOf(el)!==-1));
    let error;
    if (indexError !== -1) {
        error = availableErrors[indexError][1];
    } else {
        error = defaultError;
    }
    return messages[error];
}

async function getInfoAllenamenti (ids) {
    const params = {
        'short': '',
        'ids': ids.join(",")
    };
    return new Promise((resolve, reject)=>{
        const url = CONFIG.api_base_url + "/api/v2/calendario.php?" + URLparams(params);
        api(url, 'get')
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(null);
                }

            }).catch(
                (error)=>{
                    reject(error);
                }
            )
    });
}
export async function inviaGarmin (type = "singolo", data) {
    const params = {
        'singolo': '',
        'settimana': '?settimana',
    };
    return new Promise((resolve, reject)=>{
        const url = CONFIG.api_base_url + "/api/v2/workout.php" + params[type];

        let hasErrors;
        let message;
        api(url, 'post', data)
            .then(async (result)=>{
                if (!result.success) {
                    hasErrors = true;
                }

                if (result.success) {
                    hasErrors = result.data.reduce((acc,el)=>{
                        return  acc || !!el.hasErrors;
                    }, false);
                }

                if (!hasErrors) {
                    message = {
                        "text": "Allenamento caricato su Garmin!",
                        "type": "success"
                    };
                    resolve({
                        data: result.data,
                        message: message,
                    }
                    );
                } else {
                    let errors = [];
                    // distingui gli errori per allenamento
                    result.data.forEach((res)=>{
                        if (res.hasErrors) {
                            let error = {
                                calendarioId: res.calendario_id,
                                errorCodes: res.errors,
                                date: res.date,
                                errorMessage: getErrorMessage(res.errors),
                            }
                            errors.push(error);
                        }
                    });
                    let errorMessage;
                    if (errors.length) {
                        const ids = errors.map(el=>el.calendarioId);
                        let details = await getInfoAllenamenti(ids);

                        errorMessage = errors.reduce((acc,el)=>{
                            const detail = details.find(d=>d.id==el.calendarioId);
                            let date = funzioni.formatDate(el.date);
                            const stringDate = (date) ? date : "";
                            let rows = [];
                            rows.push(acc);
                            rows.push(stringDate);
                            rows.push(detail.data.sport_descrizione);
                            rows.push(detail.data.titolo);
                            rows.push(el.errorMessage);
                            rows.push("");
                            return rows.join("\n");
                        },"");
                    } else {
                        errorMessage = "";
                    }

                    message = {
                        "text": errorMessage,
                        "type": "error"
                    };
                    resolve(
                        {
                            data: result.data,
                            message: message,
                        }
                    );
                }
            }).catch(
                (error)=>{
                    reject(error);
                }
            )
    });
}
