<template lang="html">
  <div class="test-dettaglio msp-ui-panel">
    <div class="test-dettaglio__header">

      <div class="test-dettaglio__title">
        <h3  >
<i :class="'msp-icon-sport-'+test.sport_id"></i>        {{test.nome}}</h3>
        <p v-if="descrizione" class="test-dettaglio__note">{{test.note}}</p>
      </div>

      <div class="test-dettaglio__risultato">
        <p class="test-risultato" v-html="formatRisultatoByUmId(test.risultato, test.risultato_um_id, test.risultato_um_descrizione)"></p>
        <p class="test-dettaglio__data">{{formatDate(test.data)}}</p>
      </div>

    </div>
    <div class="test-dettaglio__note">
      <div class="msp-ui-textarea">{{test.note_test}}</div>
    </div>
  </div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";

export default {
  mixins: [
    allenamentoCalcoli,
  ],
  props: ["test", "descrizione"],

  methods: {
    formatRisultatoByUmId(val, um_id, um_descrizione) {
      return this.MSP().formatRisultatoByUmId(val, um_id, um_descrizione);
    }

  },

}
</script>

<style lang="scss">
.test-dettaglio
{
  margin: 0;

  .test-dettaglio__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  h3 {
  display: grid;
    margin: 0;
    grid-template-columns: 50px 1fr;
  }

}
.test-dettaglio__title {
  background: var(--col-grigio-chiarissimo);
  flex: 0;
  flex-basis: 50%;
  padding: calc(2 * var(--ui-space));

  i {
      font-size: 2em;
  }

 
}
.test-dettaglio__risultato {
    display: flex;
    justify-content: space-between;
    align-items: center;
  background: var(--col-grigio-chiarissimo);
  margin: 0;
  padding: 0 calc(2 * var(--ui-space));
  border: none;

  p {
      margin: 0
  }
}

.test-dettaglio__data {
    font-size: 0.9em;
  color: var(--col-grigio-medio);
    margin: 0;
}
.test-risultato {
  font-size: 1em;
  font-weight: bold;

}
  .test-dettaglio-note
  {
    background: var(--col-grigio-chiarissimo);
    padding: var(--ui-cellspacing);
  }
  .table-label
  {
    width: 30%;
  }

}

</style>
