const utente = {
    getRuolo: function (atleti, atleta_id) {
        if (!atleta_id) return false;
        let atleta = atleti.find((el) => (el.atleta_id == atleta_id));
        if (!atleta) return false;
        return atleta.ruolo_codice;
    },

    isRuoloAllenatore: function (atleti, atleta_id) {
        const ruolo_codice = utente.getRuolo(atleti, atleta_id);
        if (!ruolo_codice) return false;
        if (ruolo_codice == 'allenatore') {
            return true;
        } else {
            return false;
        }
    },

    canEdit: function (item) {
        if (!item.ruolo) { return false; }

        const ownership = item.ruolo.ownership;

        let permesso = 'edit_allenamento';
        if (ownership != 'owner') {
            permesso = permesso.split("_").join(`_${ownership}_`);
        }

        const canEdit = item.ruolo.permessi.indexOf(permesso) != -1;
        return canEdit;
    },
};

export {utente as default};
