import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import moment from "moment";
import env from "./environment.js"
import _api from "./api.js";

export default {
    mixins: [
        env,
        allenamentoCalcoli,

    ],
    data: function () {
        return {
            ids_sport: [this.MSP().sport_id.nuoto, this.MSP().sport_id.ciclismo, this.MSP().sport_id.podismo],
            nomeZone: [],
            id_tipo: '',
            id_tipo_um: 0,
            // sport_id: 0,
            tests: [],
            risultati: [],
            zone: []
        }
    },
    props: [
        'account',
        'filter-sport-ids'
    ],
    created: function() {
    },
    mounted () {
        //do something after mounting vue instance
        moment.locale('it');
    },
    computed: {
        formatSoglia: function() {
            if (!(this.soglia && this.soglia.value)) {
                return false;
            }
            return this.formatValue(this.soglia.value, this.sport_id);
        },
        formatPasso: function() {
            if (!(this.passo && this.passo.value)) {
                return false;
            }
            return this.formatValue(this.passo.value, this.sport_id);
        }
    },
    methods: {

        formatRisultato (val) {
            return val;//this.MSP().formatRisultato(val, um);

        },
        formatRisultatoFromIdTipoUm (val, id_tipo_um) {
            id_tipo_um = this.MSP().int(id_tipo_um);
            if (id_tipo_um === 1) {
                return this.MSP().formatRisultato(val, 'mm:ss');
            } else {
                return this.MSP().formatRisultato(val, id_tipo_um);
            }
        },

        filterResult: function (risultati, what) {
            var result = null;
            if (!risultati || (!Object.hasOwnProperty.call(risultati,'length')) || (risultati.length === 0)) {
                return null;
            }

            var methods = [
                ['max', 'min'],
                ['min', 'max']
            ];
            var method = (what === "best") ? methods[0] : methods[1];
            var um = risultati[0].risultato_um_descrizione;
            if ((um === 'mm:ss')
                || (um === 'mt')) {
                result = Math[method[0]].apply(Math, risultati.map((o)=>o.risultato));
            } else {
                result = Math[method[1]].apply(Math, risultati.map((o)=>o.risultato));
            }
            return this.MSP().formatRisultato(result, um) + " " + um;
        },

        bestResult: function (risultati) {
            return this.filterResult(risultati, 'best');
        },

        worstResult: function (risultati) {
            return this.filterResult(risultati, 'worst');
        },

        formatValue: function (value, sport_id) {
            var um = this.MSP().UMPasso(sport_id);
            return this.MSP().formatRisultato(value, um) + " " + um;
        },

        getUm: function (ids_tipo) {
            if (Object.hasOwnProperty.call(ids_tipo,'length')) {
                return ids_tipo[0].um;
            } else {
                return ids_tipo.um;
            }
        },


        /* START: ZONE */

        clearZone: function () {
            this.zone = [];
            if (this.sport_id) {
                this.loadEmptyZonesPromise()
                    .then((result)=>{
                        if (result.success) {
                            this.zone = result.data;
                        }
                    })
                    .catch((err)=>{
                        console.log("err",err);
                    })
            }

        },

        loadNomeZone: function () {
            //  console.log("TODO: carica i nomi zone da storage: verifica uso di ws");
            // var self = this;
            // var callback = function() {
            //   self.nomeZone = this.MSP().storage.nomezonedefault.getData();
            // }
            // this.MSP().storage.nomezonedefault.all(callback);
        },


        loadEmptyZonesPromise: function() {
            return this.MSP().api.get('zona.php?q=vuote&sport_id='+this.sport_id);
        },



        //TODO: dividere calcola da salva
        calcolaZonePromise: function (test) {
            if (Object.hasOwnProperty.call(test,"inputs")) {
                test.valore = test.inputs.valore;
                test.passo = test.inputs.passo;
                test.risultato = test.inputs.risultato;
                test.note = test.inputs.note;
                test.data = moment(test.inputs.data).format('YYYY-MM-DD H:m:s');
            }
            let valore = test.valore;
            let formula_id = test.id;

            let url = this.api_base_url+"/api/v2/risultato.php?q=zone&valore="+valore+"&test="+formula_id;

            return new Promise((resolve,reject)=>{
                _api(url, "GET")
                    .then((result)=>{
                        resolve(result);
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });

        }
        /* END: ZONE */

    }
}
