const MSP = {};
MSP.timeout = null;
MSP.doDelay = function(callback, ms) {
  clearTimeout(MSP.timeout);
  MSP.timeout = setTimeout(callback, ms);
}


MSP.doCallback = function (callback, arg) {
  if (typeof callback === 'function') {
    callback(arg);
  }
}

MSP.Callbacks = function () {
  //TODO: sort
  var callbacks = {};
  var cb = {
    list: function ()
    {
      return callbacks;
    },
    has: function (key)
    {
      return Object.prototype.hasOwnProperty.call(callbacks, key);
    },
    add: function (key, callback)
    {
      if (!this.has(key))
      {
        callbacks[key] = [];
      }
      callbacks[key].push(callback);
    },
    remove: function (key, callback)
    {
      if (this.has(key))
      {
        let i;
        let arr;
        callbacks[key] = (i = arr.indexOf(callback)) && (i !== -1) && arr.splice(i, 1)
      }
    },
    do: function (key, ...args)
    {
      if (this.has(key))
      {
        callbacks[key].map(el=>el(...args));
      }
    }
  }
  return cb;
}



/** attende una condizione per avviare un'azione
* @param {function} condition return boolean: condizione per avviare l'azione
* @param {function} action return boolean: condizione per avviare l'azione
* @return {any} arg argomento passato all'azione action(arg)
*/

MSP.doIfActions = [];

MSP.doIf= function(condition, action, arg) {

  var to;

  var check = function () {
    if (!condition()) {
      to = setTimeout(function () {check()}, 500);
    } else {
      action(arg);
      clearTimeout(to);


    }
  }

  check();
}
export { MSP as default };
