<template>
  <div class="msp-impegno-detail mx-auto msp-fixed-container" >
    <template v-if="impegno">
      <v-app-bar class="msp-fixed-header">
        <v-btn icon @click="dismissModal()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{formatDate(impegno.data)}}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <template v-if="canEdit && utente && utente.is_atleta">
            <v-btn icon @click="deleteItem()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon @click="openModal()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <msp-confirm ref="confirmDelete"></msp-confirm>
        </template>

    </v-app-bar>

      <v-container fluid class="msp-fixed-content">
        <v-card class="impegno-content">
          <div class="img-impegno">
            <i :class="'msp-icon-impegno-'+impegno.impegno_tipo_id"></i>

          </div>
          <v-card-title class="titolo">
            {{impegno.impegno_tipo_descrizione}}</v-card-title>
          <v-card-text>
            <div class="date">

            <v-chip>
              {{formatDate(impegno.data)}}
            </v-chip>
            <span  v-if="impegno.data_fine">
               -
              <v-chip>
                {{formatDate(impegno.data_fine)}}
              </v-chip>
            </span>
          </div>
          <div class="testo" v-text="impegno.descrizione"></div>
          </v-card-text>

        </v-card>

    </v-container>
    
  </template>
</div>


</template>

<script>

import moment from 'moment';
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import canEdit from "@mixins/canEdit.js";
import env from "@mixins/environment.js"
import loadEvent from "@mixins/loadEvent.js"
import _api from "@mixins/api.js";
import mspConfirm from '@components/MspConfirm.vue';
import {  mapGetters } from 'vuex'
export default {
    mixins: [
      allenamentoCalcoli,
      canEdit,
      env,
    ],
    components: {
      mspConfirm,
    },
    props: [],
    data: function() {
      return {
        atleta_id:0,
        mode:'',
        editing: {
          descrizione: false
        },
        impegno: {},
      };
    },

    watch: {
        impegno: function (no) {
            if (!(no&&no.id)) {
                this.dismissModal();
            }
        }

    },

    mounted() {
      this.init();


    },

      beforeRouteEnter (to, from, next) {
        let id = to.params.id;
        if (!from.name) {
          next('calendario');
        } else {
          loadEvent(id)
          .then((result)=>{
            next((vm)=>{
              let impegno = result.data.data;
              vm.impegno = Object.assign({}, impegno);
              vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: impegno}});
            vm.checkCanEdit(id);
          })
        });
      }
    },
  computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),

  },
  methods: {
    async deleteItem() {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "Sei sicuro?"
        )
      ) {
        this.deleteConfirm();
        this.dismissModal();
      }
    },
    deleteConfirm() {
      let id = this.impegno.id;
      this.$store.dispatch("calendario/deleteEvent", id);
    },
    openModal: function () {
      let item = this.impegno;
      this.$router.push("/calendario/impegno/edit/"+item.id);
    },
    dismissModal () {
      this.$store.dispatch("calendario/setDialog", false);
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')

    },

    init: function () { },
    update: function () {
      let data = this.impegno;
      let url = this.api_base_url+"/api/v2/calendario.php?evento="+this.impegno.calendario_id;

      return new Promise((resolve,reject)=>{
          _api(url, "PUT", data)
              .then((result)=>{
                  if (result.success) {
                      this.$store.dispatch("calendario/updateEvent", {id: this.impegno.calendario_id, event: {data: this.impegno}});

                      resolve(result);
                  } else {
                      resolve(null);
                  }
              })
              .catch((error)=>{
                  reject(error);
              })
      });
    },
    setParam: function (prop, value)
    {
      this.impegno[prop] = value;
    },
    classGiorno: function ()
    {
      let classes = [];
      classes.push('impegno');
      classes.push('impegno-'+this.impegno.impegno_tipo_id);

      return classes.join(" ");
    },

    setDate: function (prop, data) { //formato data DD/MM/YYYY
      let a_d = data.split("/");
      let n_d = a_d[2] + "-" + a_d[1] + "-" + a_d[0]
      let format_data = moment(n_d).format('YYYY-MM-DD H:m:s');
      this.setParam(prop, format_data);
    },
    submit: function ()
    {
      this.$emit("submit", this.impegno);
    }
  }

}
</script>

<style lang="scss">
.msp-impegno-detail
{
    .testo {
        padding: 10px;
    }
  .impegno-content {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .img-impegno {
    display: grid;
    align-items: center;
    justify-content: center;
    [class^=msp-icon-] {
      font-size: 50px;
    }
    i {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: grid;
      align-items: center;
      justify-content: center;
      background: var(--col-grigio-chiaro);
      box-shadow: 0 3px 5px rgba(0,0,0,0.5), 0 0 20px rgba(0,0,0,0.4);
      border: 1px solid #fff;
      margin: 30px;

    }
  }
  .impegno-titolo {
    p {
      margin: 0;
      padding:0;
      line-height: 1;
    }
    .impegno-specialita {
      font-size: 0.8em;
    }
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .impegno-data{
    margin-bottom: 1em;
  }
  .impegno-tipo{
    margin-right: 1em;
    i[class^="msp-icon-impegno-"] {
      display: block;
      font-size: 5rem;
      color: var(--col-grigio-scuro);
      margin: 1rem 0;

    }
  }
  .impegno-gruppi-dati{
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .impegno-descrizione{
    flex-grow: 1;
  }


  .titolo,
  .date {
    text-align: center;
    justify-content: center;
  }


}

</style>
