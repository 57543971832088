<template>
    <div class="msp-valutazione-mobile" >
       <div class="valutazione-dettaglio" v-if="valutazione">
       <div 
       class="valutazione-testo"
       v-text="valutazione.titolo" />
      <i :style="'--color:'+valutazione.colore" class="valutazione-icona" :class="valutazione.icona" />
        </div>
        <div class="valutazione-dettaglio placeholder" v-else>
       <div 
       class="valutazione-testo"
       v-text="placeholder.titolo" />
       <img :src="`/img/mood/${placeholder.id}-1.svg`" />
        </div> 
        <template v-if="!readonly">
            <v-slider
            v-model="slider"
            step=1
            :min=0
            :max=valori.length-1
            :color="valutazione && valutazione.colore"
            @change="select"
            ticks
            />
        </template>
    </div>
</template>

<script>
import valori from "@cached/valutazione_allenamento.json"
const DEFAULT = 2;
const component = {
    components: {
    },
    props: [
        'init-value',
        'readonly',

    ],
    computed: {
        valutazione: function () {
            return this.findValutazione(this.selected);
        },
    },
    mounted: function () {
        this.initSelected();
    },
    data() {
        return {
            valori: valori.filter(el=>el.id),
            selected: false,
            slider: DEFAULT, 
            placeholder: valori[2],
        }
    },
    watch: {
        initValue: function (nv,ov) {
            if (nv !== ov) {
                this.initSelected();
            }
        },
        slider: function (nv) {
            this.selected = nv; 
        },
    },
    methods: {
        initSelected: function () {
            if ((!this.initValue) 
            || (!(this.valori && this.valori.length))) {
                this.slider = DEFAULT;
                this.selected = false;
            } else {
                this.slider = this.valori.findIndex((el)=>el.id==this.initValue);
                this.selected = true;
            }
        },
        findValutazione: function (index) {
            if (!(this.valori && this.valori.length)) {
                return false;
            }

            return this.valori[index];
        },
        select: function (id) {
            this.slider = id;
            const valutazione = this.findValutazione(this.slider);
            if (!valutazione) return;
            let data = {
                valutazione: valutazione.id,
            };

            this.$emit("update", data);
        }
    },
}
export default component;
</script>

<style lang="scss">
.msp-valutazione-mobile {
    --color: #ccc;
    text-align: center;
    padding: 0 20px;
    .valutazione-dettaglio {
        display: grid;
        grid-template-columns: 1fr auto;
        align-content: center;
        align-items: center;
        img {
            height: 40px;
            align-self: center;
        }
        &.placeholder {
            opacity: 0.2;
            img {
                filter: grayscale(1);
            }
        }

    }
        .msp-valutazione-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-content: center;
            align-content: center;
            align-items: center;
            margin: auto;
            text-align: center;
            justify-content: center;
        }
        
        .valutazione-testo {
            --border-color: #fff;
            font-weight: bold;
            letter-spacing: 0.0333em;
            text-transform: uppercase;
            user-select: none;
            text-align: left;
            font-size: 1.5em;
            line-height: 1;
        }

        .valutazione-icona {
          background: var(--color);
          width: 1em;
          height: 1em;
          font-size: 40px;
          display: grid;
          justify-content: center;
          align-content: center;
          border-radius: 2em;
        }
    }
</style>
