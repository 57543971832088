<template>
  <div class="msp-critical-power">
        <msp-line-chart
        :config="config"
        ></msp-line-chart>
  </div>
</template>

<script>


const convertTempo = function (sec) {
    if (sec >= 3600) {
        return Math.round(sec/3600)+"h";
    }
    if (sec >= 60) {
        return Math.round(sec/60)+"m";
    }
    return sec+"s";
}
import {config} from "@mixins/configLineChart.js"
import mspLineChart from "./MspLineChart.vue"
export default {
    name: 'mspCriticalPower',
  components: {
      mspLineChart,
  },
    data() {
        return {
            currentGraph: 0,
            config: null,
        }
    },
    props: [
        'attivita',
    ],
    watch: {
        currentGraph: function () {
            this.makeGraph();
        }
    },
    mounted() {
        this.makeGraph();
    },
    methods: {
      makeGraph: function () {
            let tmp_data = this.attivita.cp.dati;
            if (!(tmp_data && tmp_data.length)) {
                return;
            }
            //{"label":"Z5","value":3,"displayValue":3,"color":"#F92011","toolText":"<H6>Z5<\/H6>Tempo tot.: 00:02:52<BR><STRONG>4.1 %<\/STRONG>"},
            let ds1_values = [];
            let ds2_values = [];
            let labels = [];
            tmp_data.forEach((el)=>{
                const value = el.value;
                const value_best = el.value_best;
                ds1_values.push(value);
                ds2_values.push(value_best);
                labels.push(convertTempo(el.tempo));
            });
            const data = {
                labels: labels,
              datasets: [
                {
                    label: 'Oggi',
                    data: ds1_values,
                    fill: false,
                    borderColor: '#000',
                    backgroundColor: '#000',
                    pointBackgroundColor:"#ffffff",
                    pointBorderWidth: 1,
                    borderWidth: 5,
                    tension: 0.1,
                },
                {
                    label: 'Migliore (3 mesi)',
                    data: ds2_values,
                    fill: false,
                    borderColor: '#ffcc00',
                    backgroundColor: '#ffcc00',
                    tension: 0.1,
                },
              ]
            };
            config.data = data;


            config.options = {
                    ...config.options,
                    interaction: {
                                mode: 'x'
                    },
                    plugins: {
                        ...config.options.plugins,
                        legend: {
                            position: 'bottom',
                        },
                        tooltip: {
                            usePointStyle: true,
                        },

                    },
                }
            this.config = Object.assign({}, config);
        },
    },
}
</script>
