<template>
  <div class="msp-zone-attive">
        <v-btn-toggle mandatory v-model="currentGraph">
            <v-btn x-small v-for="(zona, index) in attivita.zone" :key="index">
            {{zona.label}}
            </v-btn>
        </v-btn-toggle>

        <msp-bar-chart
        :config="config"
        ></msp-bar-chart>
  </div>
</template>

<script>

import {config} from "@mixins/configBarChart.js"
import {mergeDeep} from "@mixins/merge.js"
import mspBarChart from "./MspBarChart.vue"
export default {
    name: 'mspZoneAttive',
  components: {
      mspBarChart,
  },
    data() {
        return {
            currentGraph: 0,
            config: null,
        }
    },
    props: [
        'attivita',
    ],
    watch: {
        currentGraph: function () {
            this.makeGraph();
        }
    },
    mounted() {
        this.makeGraph();
    },
    methods: {
        makeGraph: function () {

            if (!(
            this.attivita.zone
            && this.attivita.zone
            && this.attivita.zone.length
            )) {
                return;
            }
            const zone = this.attivita.zone[this.currentGraph];
            let tmp_data = zone.data;
            //{"label":"Z5","value":3,"displayValue":3,"color":"#F92011","toolText":"<H6>Z5<\/H6>Tempo tot.: 00:02:52<BR><STRONG>4.1 %<\/STRONG>"},
            let values=[];
            let labels=[];
            let tooltips=[];
            let backgroundColors=[];
            tmp_data.forEach((el)=>{
                labels.push(el.label);
                values.push(el.value);
                tooltips.push(el.toolText);
                backgroundColors.push(el.color);
            });
            const data = {
                labels: labels,
                datasets: [
                {
                    label: 'Zone',
                    data: values,
                    backgroundColor: backgroundColors,
                    tooltips: tooltips,
                },
                ]
            };
            let newConfig = {
                options: {
                    plugins: {
                        tooltip: {
                            enabled: false,
                        },
                    },
                },
            };
            config.data = data;

            let mergedConfig = mergeDeep(config, newConfig);
            this.config = Object.assign({}, mergedConfig);
        },
    },
}
</script>
