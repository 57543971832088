<template lang="html">
  <div class="allenamento-dettaglio">
    <div
    v-for="(dettaglio, index) in allenamento.dettagli"
class="fase"
    :key="index"
    >
    <h3 v-text="dettaglio.des_attivita"></h3>
    <div class="pre" v-html="dettaglio.note_attivita"></div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['allenamento']
}
</script>

<style lang="scss">
</style>
