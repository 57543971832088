import {_api, URLparams} from "@src/js/msp/api.js";
import { env } from "@mixins/environment.js"
import _funzioni from "@src/js/msp/funzioni.js"
import _calendario from "@src/js/vue/mixins/calendario.js";
import _storage from "@mixins/storage.js"

let auth_token = _storage.getAuthToken();

const setAuthToken = function (at) {
    auth_token = at;
}

const sortByDate = function (ymdA, ymdB) {
    const dateA = new Date(ymdA);
    const dateB = new Date(ymdB);
    return (dateA < dateB) ? -1 : 1;

}

const formatterDate = function (value, field, headers) {
    void(field);
    void(headers);
    return _calendario.ymd2dateShort(value);
}


const grandezze = [
    {
        value: (val)=>(val),
        um: 'mt',
    },
    {
        value: (val)=>(Math.round(val*10/1000,10)/10),
        um: 'km',
    },
];
const ums = {
    'distanza': {
        umSegmento: 'mt',
        umRisultato: '/km',
    },
    'cp-ciclismo' : {
        mneSegmento: 'cp-ciclismo',
        umSegmento: 'min',
        umRisultato: 'watt',
    },
};

function getUmFromMneSegmento (mneSegmento) {
    return ums[mneSegmento];
}

const makeRecord = function (data) {
    return data.sort(sortByDate).map((el)=>{
        const formatted = {
            ...el,
        };
        const extra = formatRisultato(el);
        for(let key in extra){
            const ex = extra[key];
            if (!ex.value) {
                formatted[key] = "-";
                continue;
            }
            if (ex.um) {
                formatted[key] = `${ex.value} ${ex.um}`;
                continue;
            }
            formatted[key] = `${ex.value}`;
        }

        return formatted;
    });
}

const configBest = [
    {
        color: '#FFD700',
        classifica: '1st',
        badge: "1",
    },
    {
        color: '#C0C0C0',
        classifica: '2nd',
        badge: "2",
    },
    {
        color: '#CD7F32',
        classifica: '3th',
        badge: "3",
    },
];
export const headers = [
    {
        label: "Data",
        value: "data",
        field: "data",
        type: "date",
        sort: sortByDate,
        align: "left",
        string: formatterDate,
    },
    {
        label: "Risultato",
        value: "risultato",
        field: "risultato",
        type: "number",
        align: "right",
        string: "stringRisultato",
    },
    {
        label: "Passo",
        value: "risultato",
        field: "passo",
        type: "number",
        align: "left",
        string: "stringPasso",
    },
    {
        label: "Watt/kg",
        value: "risultato",
        field: "wattkg",
        type: "number",
        string: "wattkg",
        align: "left",
        um: "w/kg",
    },
    {
        label: "Record",
        value: "segmento",
        field: "segmento",
        type: "number",
        string: "segmento",
    },
];

const headersTipoRecord = {
    "best": [
        "segmento",
        "risultato",
        "data",
    ],
    "distanza": [
        "data",
        "risultato",
        "passo",
    ],
    "cp-ciclismo": [
        "data",
        "risultato",
        "wattkg",
    ],
}
const headersTipoTabella = {
    "best": { 
        "distanza": "best",
        "cp-ciclismo": "best",
    },
    "analisi": { 
        "distanza": "distanza",
        "cp-ciclismo": "cp-ciclismo",
    },
}

export const headersByTipoTabellaRecord = function (tipoTabella, tipoRecord) {
    if (Object.keys(headersTipoTabella).indexOf(tipoTabella) === -1) return [];
    if (Object.keys(headersTipoTabella[tipoTabella]).indexOf(tipoRecord) === -1) return [];
    const key = headersTipoTabella[tipoTabella][tipoRecord];
    const keys = headersTipoRecord[key];
    let filtered = keys.map((el)=>{
        const um = getUmFromMneSegmento(tipoRecord);
        const header = headers.find(h=>h.field===el);
        if (!header.um) {
            header.um = um.umRisultato;
        }
        return header;
    });
    return filtered;
}

export const headersByTipoRecord = function (tipoRecord) {
    if (Object.keys(headersTipoRecord).indexOf(tipoRecord) === -1) return [];
    const keys = headersTipoRecord[tipoRecord];
    let filtered = keys.map((el)=>{
        const um = getUmFromMneSegmento(tipoRecord);
        const header = headers.find(h=>h.field===el);
        if (!header.um) {
            header.um = um.umRisultato;
        }
        return header;
    });
    return filtered;
}

export const getSport = function (codice) {
    let sport = sports.find(el=>el.value==codice);
    return sport;
};

export const getSportById = function (sport_id) {
    let sport = sports.find(el=>el.sport_id==sport_id);
    return sport;
};

export const sports = [
    {
        "value": "CICLISMO",
        "label": "Ciclismo",
        "tipo_record": "cp-ciclismo",
        "tipo": "power",
        "sport_id": 2,
        "order": "desc",
    },
    {
        "value": "PODISMO",
        "label": "Podismo",
        "tipo_record": "distanza",
        "tipo": "speed",
        "sport_id": 1,
        "order": "asc",
    },

];

function aggiungiAnniListaPeriodi (listaPeriodi, anni) {
    const currentYear = new Date().getFullYear();
    for (let i=0; i<anni; i++) {
        let anno = currentYear - i;
        const periodo = {
            value: anno,
            text: anno,
        }
        if (listaPeriodi.map(el=>el.value).indexOf(periodo.value) === -1) {
            listaPeriodi.push(periodo);
        }
    }
}

function makeListaPeriodi () {

    const listaPeriodi = [
        {
            value: 'ALL',
            text: "All time",
        },
        {
            value: '90gg',
            text: "90 giorni",
        }
    ];
    return listaPeriodi;
}

export const listaPeriodi = makeListaPeriodi();

const loadCountRecord = function (atletaId) {
    let data = {
        count: 1,
        atleta_id: atletaId,
    }
    let params = URLparams(data);
    let url = env.api_base_url+`/api/v2/record.php?${params}`;

    return new Promise((resolve,reject)=>{
        _api(url,'get',null,auth_token)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(null);
                }
            })
            .catch((error)=>{
                reject(error);
            })
    });
}

const formatGrandezzaDistanza = function (mt) {
    const iGrandezza = (mt < 1000) ? 0 : 1;
    const grandezza = grandezze[iGrandezza];
    const value = grandezza.value(mt);
    const um = grandezza.um;
    return {
        value: value,
        um: um,
    }
}
const formatRisultato = function (item) {
    const segmento = item.segmento;
    if (item.mne == 'distanza') {
        const result = {};
        if ("segmento" in item) {
            const grandezza = formatGrandezzaDistanza(item.segmento);
            const value = grandezza.value;
            const um = grandezza.um;
            result.stringSegmento = {
                value: value,
                label: 'Segmento',
                key: 'stringSegmento',
                um: um
            };
        }
        if ("risultato" in item) {
            const passo =_funzioni.getPassoKm(item.risultato,segmento);
            result.stringRisultato = {
                value: _funzioni.sec2hms(item.risultato, (item.risultato < 3600)),
                label: 'Risultato',
            };
            result.passo = {
                value: passo,
                label: 'Passo',
                um: '/km',

            };
            result.stringPasso = {
                value: _funzioni.sec2hms(passo, (passo < 3600)),
                label: 'Tempo',
                um: '/km',
            };
        }
        if ("bestAnno" in item) {
            result.stringBestAnno = {
                value: _funzioni.sec2hms(item.bestAnno, (item.bestAnno < 3600)),
                label: 'Tempo',
            };
        }


        if ("bestAllTime" in item) {
            result.stringBestAllTime = {
                value: _funzioni.sec2hms(item.bestAllTime, (item.bestAllTime < 3600)),
                label: 'Tempo',
            };
        }
        return result;
    } else if (item.mne == 'cp-ciclismo') {
        const result =  {};
        if ("risultato" in item) {
            result.stringRisultato = {
                value: item.risultato,
                label: 'Risultato',
                key: 'stringRisultato',
                um: 'w',
            };
        }
        if ("segmento" in item) {
            result.stringSegmento = {
                value: _funzioni.sec2hms(item.segmento, (item.segmento < 3600)),
                label: 'Segmento',
                key: 'stringSegmento',
            };
        }
        if ("bestAllTime" in item) {
            result.stringRisultato = {
                value: item.risultato,
                label: 'Risultato',
                key: 'stringRisultato',
                um: 'w',
            };
        }
        if ("dettaglio" in item) {
            result.wattkg = {
                value: item.dettaglio,
                label: 'Dettaglio',
                key: 'dettaglio',
                um: 'w/kg',
            }
        }
        if ("bestAllTime" in item) {
            result.stringBestAllTime = {
                value: item.bestAllTime,
                label: 'Risultato',
                um: 'w',
            };
        }
        return result;
    }
}
export default {
    sortByDate,
    headers,
    headersByTipoTabellaRecord,
    headersByTipoRecord,
    getSport,
    getSportById,
    sports,
    listaPeriodi,
    aggiungiAnniListaPeriodi,
    loadCountRecord,
    formatRisultato,
    formatGrandezzaDistanza,
    configBest,
    makeRecord,
    setAuthToken,
}
