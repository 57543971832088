import _msp_interfaces from './interfaces.js';

const constructors = {}
constructors.Test = function() {

  var a = {
    descrizione:"",
    formula:"",
    test_id:"",
    sport_id:"",
    nome:"",
    note:"",
    zona_gruppo_id:0

  }
  return a;
}
constructors.unique = function(id) {
  let newID = +new Date();
  if (typeof id === 'undefined') {
    return newID +1 ;
  }
  let params = id.split("_");
  if (params.length === 1) {
    return ++id;
  }
  if (params[0] === "not") {
    if (newID === params[1]) {
      return newID + 1;
    }
  }
  return newID;
}


constructors.getDefaultUmFromUms = function (ums)
{
  if (ums && ums.length)
  {
    return ums[0].um_id;
  }
  return undefined;
}

constructors.Andatura = function(id, ums) {
  if(typeof id === 'undefined') {
    id = constructors.unique();
  }

  var a = {
    'id': id,
    'hms': '00:00:00',
    'val_andatura': 0,
    'um_id': constructors.getDefaultUmFromUms(ums),
    'des_zona': '',
  }
  return a;
}
constructors.Serie = function(id, ums) {
  if(typeof id === 'undefined') {
    id = constructors.unique();
  }
  var a = {
    'ripetute': 1,
    'andature': [],
  }
  a.andature.push(constructors.Andatura(undefined, ums));
  return a;
}

constructors.Dettaglio = function (sport_id, ums) {
  let dettaglio;
  if (+sport_id === +constructors.ID_PALESTRA)
  {
     dettaglio =  {
      'id': constructors.unique(),
      'ums': [],
      'categoria': {},
      'esercizio': {},
      'des_attivita': '',
      'totale': Object.assign({},_msp_interfaces.totale())
    }
  }
  else
  {

     dettaglio =  {
      'id': constructors.unique(),
      'ums': [],
      'des_attivita': '',
      'serie': [
        constructors.Serie(undefined, ums)
      ],
      'totale': Object.assign({},_msp_interfaces.totale())
    }
  }
  return dettaglio;
}

constructors.Risultato = function () {
  var risultato =  {
    "calcolato":"",
    "data":"",
    "atleta_id":"",
    "sport_id":"",
    "id_test":"",
    "id_test_atleta":"",
    "id_tipo":"",
    "is_active":"",
    "nome":"",
    "note_test":null,
    "passo":"",
    "ranking":"",
    "risultato":"",
    "fc_max":"",
    "fc_media":"",
    "sport":"",
    "um":"",
    "zone":"",
  }
  return risultato;
}


constructors.Passo = function () {
  var passo =  {
    value: 0,
    label: ''
  }
  return passo;
}



constructors.Informazioni = function () {
  return {
    'zone':[],
    'zone_risultati': null,
    'densita': 0,
    'mets':[],
    'trimps':[],
    'workload':[],
    'trimp':[],
    'totale':[],
    'ums': []

  }
}


export { constructors as default};
