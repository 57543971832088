const distanze = [
    {
        "id": "50",
        "name": "50",
    },
    {
        "id": "100",
        "name": "100",
    },
    {
        "id": "200",
        "name": "200",
    },
    {
        "id": "400",
        "name": "400",
    },
    {
        "id": "C",
        "name": "Continuo",
    },
];
export default distanze;
