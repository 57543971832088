
const config = {
    type: 'line',
    data: null,
    options: {
        aspectRatio: 3/2,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "tempo",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "potenza",
                },
            },
        },
        plugins: {
            legend: {
                align: 'start',
            },
        }
    }
};


export {
    config,
}
