const colors = [
    "#ffffff",
    "#eeeeee",
];

export function findZoneAttiveSport(zoneAttive, sport, tipo) {
    let zonaAttiva = zoneAttive.find(el=>{
        return sport.toLowerCase() === el.sport_descrizione.toLowerCase()
        && tipo.toLowerCase() === el.um_tipo_codice.toLowerCase()
    });
    return zonaAttiva;
}

export function findZonaAttiva(zoneAttive, sport, tipo, codice) {
    let zonaAttiva = findZoneAttiveSport(zoneAttive, sport, tipo);
    if (!zonaAttiva) return "?";
    let zona = zonaAttiva.zone.find(el=>el.codice.toLowerCase() === codice.toLowerCase());
    return zona;
}

export function renderZonaAttiva(zoneAttive, sport, tipo, codice) {
    let zona = findZonaAttiva(zoneAttive, sport, tipo, codice);
    if (!zona) return "...";
    return zona.da_string + " - " + zona.a_string;
}

const plugin_zebra = {
  id: 'pms_zebra',
   beforeDatasetsDraw: function (chart) {
            const ctx = chart.canvas.getContext('2d');
            const meta = chart.getDatasetMeta(0);


            const ticks = meta.vScale.ticks;
            const left = meta.vScale.left;
            const size = meta.vScale.max;
            const width = meta.vScale.width;
            const pixels = width/size;
            const top = meta.yScale.top;
            const bottom = meta.yScale.bottom;



            let colorIndex = 0;   
            ticks.forEach((tick, i)=>{
                let pre = i - 1;
                if (pre < 0) {
                    return;
                }
                ctx.save();
                ctx.fillStyle = colors[colorIndex];
                let x1 = left+ticks[pre].value*pixels;
                let x2 = left+ticks[i].value*pixels;
                ctx.fillRect(x1, top, x2, bottom);
                ctx.restore();
                colorIndex++;
                if (colorIndex === colors.length) {
                    colorIndex = 0;
                }

            });
    },
};
const plugin_label_right = {
  id: 'pms_label_right',
   afterDatasetsDraw: function (chart) {
       let ctx = chart.ctx;
        const max_x = chart.width;
        const margin = 30;
         chart.data.datasets.forEach(function (dataset, i) {
                var meta = chart.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    let x = bar.x;
                    let y = bar.y+5;
                    if (x+margin > max_x) {
                        x -= 5;
                        ctx.textAlign = "right";
                    } else {
                        x += 5;
                        ctx.textAlign = "left";
                    }
                    ctx.fillText(data, x, y);
                });
            });
    },
};

const config = {
    type: 'bar',
    data: null,
    plugins:  [
        plugin_zebra,
    ],
    options: {
        aspectRatio: 2,
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        responsive: true,
        plugins: {
            pms_zebra: {},
            pms_label_right: {},
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                // external: externalTooltipHandler,
                // callbacks: label function(context) return label
            },
            datalabels: {
                color: 'white',
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 15;
                },
                font: {
                    weight: 'bold'
                },
                formatter: Math.round
            }
        },
        onComplete: function () {
            const chartInstance = this.chart;
            const ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
        }
    },
};
const configLaps = {
  type: 'bar',
  data: null,
  plugins: [],
    options: {
        aspectRatio: 2,
        plugins: {
            tooltip: {
                enabled: true,
                // external: externalTooltipHandler,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.tooltips[context.dataIndex].map((el)=>{
                            return el.label+": "+el.value;
                        });
                        return label;
                    }, 
                },
            },
            legend: {
                display: false,
            },
        },
    },
};


export {
    colors,
    plugin_zebra,
    plugin_label_right,
    config,
    configLaps,
}
