import _api from "./api.js";
export default function (id) {
  let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/calendario.php?id="+id;

  return new Promise((resolve,reject)=>{
      _api(url, "GET")
    .then((result)=>{
        if (result.success) {
          resolve(result);
        } else {
          resolve(null);
        }
      })
      .catch((error)=>{
        reject(error);
      });
  });
}
