<template >
  <div class="msp-add-test mx-auto msp-fixed-container" >
  <v-app-bar class="msp-fixed-header" >
  <v-btn icon @click="dismissModal()">
    <v-icon>mdi-chevron-left</v-icon>
  </v-btn>

  <v-toolbar-title>
    <div class="test-titolo">
      <h2>{{formattedDate}}</h2>
    </div>
  </v-toolbar-title>


</v-app-bar>



<v-container class="msp-fixed-content">




<v-card v-if="!test">
      <v-card-text>
      <v-select
      class="select-test"
      :items="tests"
      v-model="testId"
      item-text="nome"
      item-value="id"
      @change="loadTest"
      label="Seleziona un test"
      outlined
      >
      <template v-slot:item="{  item, attrs, on }">
      <div class="select-test-item" v-on="on" v-bind="attrs" >
      <span class="select-icon-sport"  :style="'--color: '+item.sport_colore" >
      <i class="msp-flat-icon" :class="iconSport(item.sport_codice)"></i>
      </span>
      <span>{{ item.nome }}</span>
      </div>
      </template> 
      </v-select>
      </v-card-text>
    </v-card>
    
    <v-card v-if="test">
    <v-app-bar
    :color="test.sport_colore"
    dense
    dark
    >

    <v-toolbar-title>
      {{test.nome}}
</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
    v-if="!fixedTest"
   @click="closeTest"
   icon >
    <v-icon>mdi-close</v-icon>
    </v-btn>
    </v-app-bar>
      <v-card-text>
      
      
      <calcolo-test
      :passo="passo"
      :soglia="soglia"
      :fixed-data="date"
      :sport_id="sport_id"
      :test="test"
      :account="account"
      @on_calcola='setResultsCalcolo'>
    </calcolo-test >



  <zone :sport_id="sport_id" :um_id="test.um_id" :nome_zone="nomeZone"  :zone="zone" :um_descrizione="test.um_descrizione"></zone>

  <div class="msp-ui-panel">
    <div v-show="formatSoglia" class="msp-ui-value">
      <div class="msp-ui-panel-header">
        <h3 v-text="test.descrizione_soglia"></h3>
      </div>
      <p v-html="formatRisultatoByUmId(soglia.value, test.um_id, test.um_descrizione)"></p>

    </div>
  </div>


      </v-card-text>
    </v-card>
      
    
  

</v-container>


<div class="msp-fixed-footer">
    <template v-if="risultato">
        <v-btn block color="secondary" class="btn-save" @click="saveAndClose">Salva</v-btn>
</template>
</div>

</div>


</template>

<script>
import env from "@mixins/environment.js"
import api from "@mixins/api.js";
import funzioni from "@src/js/msp/funzioni.js"

import { mapGetters } from 'vuex'
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import testCalcolatore from "@mixins/test_calcolatore.js"
import calcoloTest from "@components/calcoloTest.vue";
import zone from '@src/js/vue/include/Zone.vue'

export default {
  components: {
    zone,
    calcoloTest,
  },
  mixins: [
    allenamentoCalcoli,
    testCalcolatore,
    env,
  ],
  data: function() {
      return {
          passo: new this.MSP().Passo(),
          soglia: new this.MSP().Passo(),
          test: null,
          testId: 0,
          tests: [],
          fixedTest: false,
          date: funzioni.formatDateYmd(new Date()),
      };
  },

watch: {

},

mounted() {
  this.init();
},

computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),

    formattedDate: function () {
        if (!this.date) {
            return "";
        }
        return this.formatShortDay(this.date);
    },
  formattedRisultato: function () {
    return this.formatRisultatoByUmId(this.soglia.value, this.test.um_id, this.test.um_descrizione);
  },
  sport_id () {
    return this.test && this.test.sport_id;
  },
},
methods: {
  init: async function () {
      let id = this.$route.params.id;
      let date = this.$route.params.date;
      if (id) {
          this.testId = id;
          this.date = date;
          this.fixedTest = true;
          this.loadTest();
      } else {
          this.clear();
          this.loadTests();
      }
  },

    iconSport: function (codice) {
    return "msp-icon-sport-"+codice;
},

  saveAndClose () {
    this.saveActive()
    .then(
      async (result)=>{
      if (result.success) {
        let limits = {
          start: this.date,
          end: this.date,
        }
        await this.$store.dispatch("calendario/loadCalendar",limits);
      }
        this.dismissModal();
    })

  },

  loadTests: function () {
      let url = this.api_base_url+"/api/v2/test.php?elenco";
      api(url,'get')
      .then((result)=>{
          if (result.success) {
              this.tests = result.data;
          }
      });

  },
  
  closeTest: function () {
      this.clear();
      this.testId = 0;
      this.test = null;
  }, 

  loadTest: function () {
      if (!this.testId) return;
      let url = this.api_base_url+"/api/v2/test.php?dettaglio&id="+this.testId;
      api(url,'get')
      .then((result)=>{
          if (result.success) {
              this.test = result.data;
          }
      });
  },

  dismissModal () {
      this.closeTest();
      this.$router.go(-1);

  },

  submit: function ()
  {
    this.$emit("submit", this.test);
  },

  formatRisultatoByUmId(val, um_id, um_descrizione) {
    return this.MSP().formatRisultatoByUmId(val, um_id, um_descrizione)
  },

}

}
</script>

<style lang="scss">
    .select-test-item {
        display: grid;
        grid-template-columns: 2em 1fr;
        flex-grow: 1;
        margin-bottom: 15px;
    }
.msp-add-test {
    .v-bottom-navigation .v-btn__content {
        color: white !important;
    }
    .v-bottom-navigation .v-btn {
        font-size: inherit !important;
    }

    .select-icon-sport {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.2em;
        height: 2.2em;
        font-size: 0.8em;
        background-color: var(--color);
        border-radius: 3px;
    };

    .msp-add-test {

        .btn-save {
            margin-bottom: 5px;
        }
        .btn-risultato {
            display: inline-block;
            background-color: #fff;
            color: #000;
            padding: 0 0.1em;
            margin: 0 0.5em;
        }
    }
}
</style>
