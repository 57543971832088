import _msp_fn from './funzioni.js';
import _msp_api from './api.js';
import LZString from 'lz-string';

const MSP = {};

MSP.localStorage = {
  set: function (name, val) {
    localStorage.setItem(name, val);
  },
  remove: function (name) {
    localStorage.removeItem(name);
  },
  get: function (name) {
    return localStorage.getItem(name);
  },
  setJSON: function  (name, obj)
  {
    MSP.localStorage.set(name, JSON.stringify(obj));
  },
  getJSON: function  (name)
  {
    let obj = MSP.localStorage.get(name);
    return _msp_fn.isValidJSON(obj) && JSON.parse(obj) || false;
  },
}


/** Clipboard per gestire copia / incolla
* type: calendario | periodo
* data: int | object
* Nel caso di type "calendario", può contenere solo un valore, dove id = calendario_id
* Nel caso di type "periodo", contiene un array di oggetti di tipo {id, week, day}
*/
MSP.clipboard = {
  copy: function  (obj)
  {
    MSP.localStorage.setJSON('clipboard', obj);
  },
  paste: function  ()
  {
    return MSP.localStorage.getJSON('clipboard');
  },
  clear: function  ()
  {
    MSP.localStorage.remove('clipboard');
  },
  isEmpty: function ()
  {
    return !MSP.localStorage.get('clipboard');
  },
  typeOf: function (type)
  {
    let cb = this.paste();

    if (!(cb && cb.type))
    {
      return false;
    }
    {
      return cb.type === type;
    }
  }
}


/** Clipboard per gestire copia / incolla
* type: calendario | periodo
* data: int | object
* Nel caso di type "calendario", può contenere solo un valore, dove id = calendario_id
* Nel caso di type "periodo", contiene un array di oggetti di tipo {id, week, day}
*/
MSP.history = {
  limit: 5,
  push: function  (obj)
  {
    let history = MSP.history.load();
    if (!history)
    {
      history = [];
    }
    if (history.length < MSP.history.limit)
    {

      history.push(obj);
    }
    else {
      history.splice(MSP.history.limit-1,1,obj);
    }

    let string = JSON.stringify(history);
    let compressed = LZString.compressToUTF16(string);
    MSP.localStorage.set('history', compressed);
  },
  load: function  ()
  {
    let compressed = MSP.localStorage.get('history');
    // controlla vecchi storage in formato json
    if (_msp_fn.isValidJSON(compressed))
    {
      return JSON.parse(compressed) || [];
    }

    // se non esiste...
    if (!compressed)
    {
      return [];
    }

    let history = LZString.decompressFromUTF16(compressed);
    return JSON.parse(history) || [];
  },
  fetch: function  (index)
  {
    let history = MSP.history.load();
    return  history && (history.length > index + 1) && history[index] || false;
  },
  clear: function  ()
  {
    MSP.localStorage.remove('history');
  },
  count: function ()
  {
    let history = MSP.history.load();
    return history && history.length;
  },
  isEmpty: function ()
  {
    return !MSP.localStorage.get('history');
  },
}

MSP.storage = {};
MSP.vue = {};



MSP.getIdAtleta = function () {
  let account = MSP.getAccount();
  return account && account.atleta_id;
}
MSP.getAccount = function () {
  let account = MSP.localStorage.getJSON('account') || false;
  let types = ['atleta','gruppo'];

  if (account && (types.indexOf(account.type)!==-1))
  {
    return account;

  }
  else
  {
    return false;
  }
}

MSP.fetchIdAtleta = async function () {
  let atleta = await _msp_api.get("atleta.php?my_id", "/api/v2");
  if (atleta.success) {
    return +atleta.data;
  } else {
    return false;
  }
}

MSP.setIdAtleta = function () {
  return false;
}
MSP.setAccount = function (account) {
  MSP.localStorage.setJSON('account', account);

}
export { MSP as default };


MSP.vue = {
  fns: [],
  ready: function (fn)
  {
    this.fns.push(fn);
  },
  isReady: function ()
  {
    this.fns.forEach(fn=>{fn();});
  }
}
