<template>
    <div
            class="msp-risultato-detail mx-auto"
            >
            <div v-if="!risultato" class="page-skeleton page-loading">
                Loading...
            </div>
        <div v-if="risultato">
            <v-app-bar app dark  :color=" 'var(--col-sport-'+risultato.sport_id+')'"
                       >

                       <v-btn icon @click="dismissModal()">
                           <v-icon>mdi-chevron-left</v-icon>
                       </v-btn>


                       <v-spacer></v-spacer>
                       <v-btn icon @click="deleteItem()">
                           <v-icon>mdi-delete</v-icon>
                       </v-btn>
                       <msp-confirm ref="confirmDelete"></msp-confirm>

            </v-app-bar>
            <v-app>


                <v-container fluid>
                    <v-sheet>

                        <test-dettaglio :test="risultato">
                        </test-dettaglio>
                        <zone :sport_id="risultato.sport_id" :um_id="risultato.um_id" :um_descrizione="risultato.um_descrizione"  :zone="risultato.zone" debug="zone attive"></zone>
                    </v-sheet>
                </v-container>

            </v-app>
        </div>
    </div>
</template>



<script>

import api from "@mixins/api.js";
import env from "@mixins/environment.js"
import testDettaglio from '@components/testDettaglio.vue'
import zone from '@src/js/vue/include/Zone.vue'

  import mspConfirm from '@components/MspConfirm.vue';


const component = {
  components: {
      zone,
      testDettaglio,
      mspConfirm,
  },
  mixins: [
    env,
  ],
  props: [
  ],
  watch: {
        attivita: function (no) {
            if (!(no)) {
                this.dismissModal();
            }
        }
  },
    computed: {
    },
  data() {
      return {
          calendario_id: 0,
          risultato: null,
      }
  },
  mounted: function () {
      this.init();
  },

  methods: {
      init: async function () {

      this.calendario_id = this.$route.params.id;
      let id = await this.getRisultatoIdFromCalendarioId(this.calendario_id);
      if (!id) {
          alert("Nessun risultato valido");
          this.dismissModal();
      }
      this.loadRisultato(id)
      .then((result) => {
          this.risultato = result.data;
      });
  },

    async deleteItem() {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "Sei sicuro?"
        )
      ) {
        this.deleteConfirm();
        this.dismissModal();
      }
    },
    deleteConfirm() {
        this.$store.dispatch("calendario/deleteEvent", this.calendario_id);
    },

    dismissModal () {
      this.$router.go(-1);
    },

    getRisultatoIdFromCalendarioId: function (calendario_id) {
        let url = this.api_base_url + "/api/v2/calendario.php?id="+calendario_id;
        return new Promise((resolve)=>{
            api(url,'get')
            .then(
            (result)=>{
                if (result.success) {
                    resolve(result.data.data.risultato_id);
                } else {
                    resolve(false);
                }

            },
            () =>{
                resolve(false);
            }
            );
        });
    },

    loadRisultato: async function (id) {
        let url = this.api_base_url + "/api/v2/risultato.php?id="+id;
        return new Promise((resolve, reject)=>{
            api(url,'get')
            .then(
            (result)=>{
                resolve(result);
            },
            (error) =>{
                reject(error);
            }
            );
        });
    },
  }
}
export default component;
</script>
<style lang="scss">
.page-skeleton {
    background: #fff;
    height: 100vh;
    color: var(--col-grigio-chiaro);
    font-size: 3em;
    display: grid;
    align-items: center;
    justify-content: center;
}
.msp-attivita-detail
{
table.dati {
  width: 100%;
  table-layout: fixed;
  
     th {
				font-weight:bold;
        text-align: left;
        border-bottom: 1px solid #ccc;  
    } 
     td {
        text-align: left;
        border-bottom: 1px solid #ccc;  
    } 
    .label {
        font-weight:bold;
        
    }
}
    .msp-card {
      --bg-col: var(--col-grigio-chiarissimo);
      border-radius: 5px;
      dispay: flex;
      flex-direction: column;
      background-color: var(--bg-col);
      padding: 5px;
      margin: 10px 5px; 
    }
    .msp-card__title {
      text-align: center;
      padding: 0px;
    }

    .msp-card__content {
      font-weight: bold;
      font-size: 1em;
      text-align: center;
      padding: 0px;
      text-align: center;
    }

    .boxes {
        display: flex;
        .msp-card {
            flex: 1;
        }
    }

  .v-tab {
    padding: 0;
    min-width: 45px;
    .small {
      letter-spacing: 0;
      text-transform: none;
      font-size: 9px;
    }
  }
  .grafico-andature {
    margin: 5px 0;
    padding: 5px 5px 0 5px;
    display: flex;
    height: 50px  ;
    .msp-grafico-andature {
      max-width: 500px;

    }

  }

  .attivita-dettaglio {
    .msp-andature-dettaglio {
      display: none;
    }
    .note-attivita {
      display: block;
    }
    &.collapsed {
      .attivita-palestra-col2,
      .attivita-palestra-col3 {
        display: none !important;
      }
    }
    &.auto {
      .msp-andature-dettaglio {
        display: block;
      }
      .note-attivita {
        display: none !important;
      }

    }
    gap: 2px;
    flex-wrap: wrap;
    .dati-item {
      display: flex;
      flex: 1;
      white-space: nowrap;
      padding: 0 10px;
      align-items: center;
      background: var(--col-grigio-chiarissimo);
      gap: 3px;
      border-radius: 5px;
    }
    .dati-value {
      font-weight: bold;

    }
  }
  .attivita-feedback-container {
    overflow-x: auto;
    width: 100%;
    position: relative;
  }
  .note-allenatore {
    padding: 5px;
    border: 2px solid var(--col-ui-evidenza);
    border-radius: 5px;
    margin: 5px 0;
    background: var(--col-ui-evidenza-tint);
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;

      }
    }
  }
  h2 {
    font-size: 1.5em;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .small {
    font-size: 10px;
    display: block;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
  .switch-mode {
    margin: 5px 10px;
  }
  .chip-percentuale {
    font-size: 30px;
    font-weight: bold;
  }


}

</style>
