<template>

  <div class="input-test" :class="{'data':!fixedData}">
    <v-card class="slot-descrizione">
      <template v-if="!test.id">

        <p class="descrizione">Inserisci il risultato per calcolare le tue zone di attività</p>
      </template>
      
  <template v-else>
  <v-expansion-panels>
  <v-expansion-panel
  >
  <v-expansion-panel-header>
  Istruzioni
  </v-expansion-panel-header>
  <v-expansion-panel-content>
        <header class="msp-ui-card-header">
          <h3>{{test.nome}}</h3>
        </header>
        <p class="descrizione" v-html="test.descrizione"></p>
  </v-expansion-panel-content>
  </v-expansion-panel>
  </v-expansion-panels>
  </template>

    </v-card>


    <v-card class="card-input-test">
    

    <v-card-text>
    <input-select :label="test.label" v-show="isHms(test.risultato_um_tipo_id)" format="ms" class="valore-input" v-model="valore"  @input="checkCalcola" placeholder="mm:ss"
    ></input-select>

    <input-select :label="test.label" format="number" v-show="!isHms(test.risultato_um_tipo_id)" class="valore-input"
    @input="checkCalcola"
    v-model="valore"
    >
    </input-select>

    <div v-if="errors.length && errors[0] !=='empty'">
    <v-alert 
    dense
    type="info"
    v-for="error in errors" :key="error" >{{ error }}</v-alert>

    </div>


    </v-card-text>
    </v-card>

  <div v-if="!fixedData" class="msp-ui-pane slot-data">
    <header>
      <h4>Data</h4>
    </header>

    <v-dialog
           ref="dialog"
           v-model="modal"
           :return-value="data"
           persistent
           width="290px"
         >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
      v-on="on"
      v-bind="attrs"
      >
        <v-icon>mdi-calendar</v-icon> {{formatData(data)}}
    </v-btn>
  </template>

  <v-date-picker
  locale="it"
  v-model="data">

  <v-spacer></v-spacer>
  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
  <v-btn text color="primary" @click="$refs.dialog.save(data)">OK</v-btn>

</v-date-picker>
</v-dialog>

</div>



  <div><v-textarea 
  label="Note"
  auto-grow v-model='inputs.note' ></v-textarea></div>



</div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import inputSelect from "./inputSelect.vue";
import moment from "moment";
export default {
  mixins: [
    allenamentoCalcoli,
  ],
  components: {
    inputSelect,

  },
  props: [
    'test',
    'sport_id',
    'passo',
    'soglia',
    'fixedData',
  ],
  data: function () {
    const data = {
      data: moment(new Date()).format('YYYY-MM-DD H:m:s'),
      note: '',
      modal: false,
      ids_tipo: [],
      errors: [],
      valore: 0,
      inputs: {},

    };
    return data;
  },
  watch: {
    test: function() { // watch it
      this.resetValues();
    },
    fixedData: function ()
    {
      if (this.fixedData)
      {
        this.data = this.fixedData;
        this.inputs.data = this.fixedData;
      }

    }
  },
  mounted: function ()
  {
    this.validateForm();
    this.resetValues();
    if (this.fixedData)
    {
      this.data = this.fixedData;
      this.inputs.data = this.fixedData;
    }
  },

  computed: {
    formatSoglia () {
      if (!(this.soglia && this.soglia.value)) {
        return false;
      }
      return this.soglia.value;// this.formatValue(this.soglia.value, this.sport_id);
    },
    formatPasso () {
      if (!(this.passo && this.passo.value)) {
        return false;
      }
      return this.passo.value;// this.formatValue(this.passo.value, this.sport_id);
    }
  },


  //TODO: correggere memory leak (emit)
  methods: {
    clearField: function (data)
    {
      this[data] = this[data] || "";
    },
    setParam: function (key, value)
    {
      this.inputs[key] = value;

    },

    formatValue (value, sport_id)
    {
      var um = this.MSP().UMPasso(sport_id);
      return  this.MSP().formatRisultato(value, um) + " " + um;
    },
    setDate: function (prop, data/*/ DD/MM/YYYY */)
    {
      var a_d = data.split("/");
      var n_d = a_d[2] + "-" + a_d[1] + "-" + a_d[0]
      this.data = moment(n_d).format('YYYY-MM-DD H:m:s');
      this.setParam(prop, this.data);
    },

    isHms: function (risultato_um_tipo_id)
    {
      return this.MSP().isUmTipoHms(risultato_um_tipo_id);
    },

    maskFromUm: function (um)
    {
      if (um === "mm:ss")
      {
        return "##:##";
      }
      return "#####";
    },
    isValid: function ()
    {
      return this.errors.length === 0;
    },

    calcola: function ()
    {
      var risultato = (this.isHms(this.test.risultato_um_tipo_id)) ? this.MSP().hms2sec(this.valore) : this.valore;

      let inputs = {
        id_test: this.test.id,
        sport_id: this.sport_id,
        valore: risultato,
        passo: this.passo,
        data: this.data,
        risultato: risultato,
      }
      this.inputs = Object.assign(this.inputs, inputs);
      this.$emit('calcola', this.inputs);

    },

    checkErrors: function (valore) {
      var errors = [];

      if(this.MSP().isHms(this.test.um_id)) {
        if (this.MSP().hms2sec(valore) <= 0) {
          errors.push('Inserisci il risultato del test');
        }
      } else {
        if (parseInt(valore, 10) <= 0) {
          errors.push('Inserisci il risultato del test');
        }
      }
      return errors;
    },

    validateForm: function () {
      this.errors = [];
      this.errors = this.errors.concat(this.checkErrors(this.valore));
      this.$emit('on_update', this.isValid());
    },

    resetValues: function () {
      this.valore = 0;
    },

    checkCalcola: function ()
    {
      var self = this;
      this.MSP().doDelay(
        function()
        {
          self.doCheckCalcola()
        },
        500
      );
    },
    doCheckCalcola: function ()
    {
      //e.preventDefault();
      //verifica unità di misura
      this.validateForm();

      if (this.isValid())
      {
        this.calcola();
      }
      else
      {
        return false;
      }
    }

  }

}
</script>

<style lang="scss">
.input-test {
        --padding: 8px;
        --top: 10px;
    .v-input__control {
        font-size: 2em;
    }
    .v-text-field__slot{
        padding-top: var(--top);
    }

    input {

        font-size: 1;
        line-height: 1em;
        position: relative;
        padding: var(--padding) 0 var(--padding) 0;;
        max-height: none;
        margin-bottom: var(--top);
    }
    fieldset {
        height: calc(2 * var(--padding) + 1.3em + 2 * var(--top));
    }
    --columns: none;
    @media(max-width:400px) {
        --columns: none;
    }
    input,
    textarea {
        background: #fff;
    }

    .descrizione {
        white-space: pre-line;
    }
    display: grid;
    width: 100%;
    height: fit-content;
    background-color: white;
    grid-template-columns: var(--columns);
    grid-column-gap: 0.3em;
    grid-row-gap: 0.3em;

    .slot-descrizione {
        grid-column: 1 / data-end;
    }

    .slot-valore {
        input  {
            grid-column: 1 / 2;
            width: 100%;
            border: 1px solid var(--col-grigio-chiaro);
            padding: 5px 8px;
        }
    }

    .slot-note {
        grid-column: 1/data-end;
    }

    .slot-fc {
        grid-column: 2 / data-end;

        input {
            width: 8em;
        }
    }
    &.data {
        --data-columns: 1fr 1fr 1fr;
        @media(max-width:800px) {
            display: block;
            --data-columns: 1fr;
        }
        grid-template-columns: var(--data-columns);
        .slot-fc {
            grid-column: 2 / 3;
        }

        .slot-data {
            grid-column: 3 / data-end;
        }
    }

    //
    // [class^="slot-"] textarea,
    // [class^="slot-"] input {
        //   width: 100%;
    // }


}

</style>
