const scaffold = {
    totale: function () {
        return {
            sec: 0,
            mt: 0,
            trimp: 0,
            zone: [],
            lavoro: [],
            hasError: false
        }
    }
};

export {scaffold as default};
