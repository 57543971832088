const colors = [
];
const containers = {
    full: [
        "bold",
        "italic",
        "underline",
        { align: ['', 'center', 'right', 'justify']},
        { 'size': ['small', false, 'large', 'huge'] },
        "strike",
        "blockquote",
        { background: colors},
        { 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' },
        { 'indent': '-1'}, { 'indent': '+1' },          // outdent/indent
        "link",
        // "image",
        { color: colors},
        "divider",
        "emoji"
    ],
    minimal: [
        ['bold', 'italic', 'underline', 'strike', 'divider' ],
        ['emoji'],   
    ],
    empty: [],
}
export default containers;

