  <template>
    <div class="msp-gara-detail mx-auto msp-fixed-container" >
    <template v-if="gara">
      
      <v-app-bar :color="color(gara.sport_id)" class="msp-fixed-header"
     :style="styleBar"
      >
      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title>
        <i class="ev-all__icon" :class="'msp-icon-sport-'+gara.sport_id" alt=""></i>

      <img
      :src="'/img/coppa.svg#important-'+gara.importanza"
      class="img-gara"
      >
     
      </v-toolbar-title>

      <v-spacer></v-spacer>
        <template v-if="canEdit">
            <v-btn icon @click="deleteItem()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon @click="openModal()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <msp-confirm ref="confirmDelete"></msp-confirm>
        </template>

    </v-app-bar>
      <v-container fluid class="msp-fixed-content">

          <v-card >
              <v-card-text>
                  <div class="gara-titolo">

                      <p class="gara-specialita">{{gara.sport_specialita_descrizione}}</p>
                      <p class="gara-data">  {{formatDate(gara.data)}}</p>
                  </div>
                  <div>
                      <template v-if="gara.luogo">
                          <span class="ev-all__icon--localita msp-icon-localita" aria-hidden="true"></span>
                          <span class="ev-all__content--localita">{{gara.luogo}}</span>
                      </template>

                  </div>

                  <p class="display-1 text--primary" >
                  {{gara.descrizione}}
                  </p>


                  <textarea readonly v-if="gara.note" class="note" v-text="gara.note"> </textarea>

              </v-card-text>
          </v-card>

              <v-card class="mb-2 card-feedback card-valutazione">

                  <v-card-title>Risultato post gara</v-card-title>
                  <v-card-text>
                      <div class="gara-gruppi-dati gara-tempo">  <label class="control-label">Tempo realizzato</label>
                          <input-select placeholder="hh:mm:ss" format="hms" class="form-control" @input="updateFeedback" v-model="feedback.risultato"></input-select>
                      </div>
                  </v-card-text>
              </v-card>     

              <v-card class="card-feedback card-valutazione">

                  <v-card-title>Come è andata la gara?</v-card-title>
                  <msp-valutazione @update="updateValutazione" :init-value='gara.valutazione' ></msp-valutazione>
              </v-card>



    </v-container>
    
    </template>
    </div>


  </template>

  <script>

  import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
  import canEdit from "@mixins/canEdit.js";
  import env from "@mixins/environment.js"
  import api from "@mixins/api.js"
  import loadEvent from "@mixins/loadEvent.js"
  import mspConfirm from '@components/MspConfirm.vue';
  import mspValutazione from '@components/MspValutazione.vue';
  import inputSelect from "@components/inputSelect.vue";

  export default {
    mixins: [
      allenamentoCalcoli,
      canEdit,
      env,
    ],
    components: {
      mspConfirm,
      inputSelect,
      mspValutazione,
    },
    props: [],
    data: function() {
        return {
            atleta_id: 0,
            mode:'',
            editing: {
                descrizione: false
            },
            gara: null,
            sports: [],
            specialita: [],
            feedback: {
                risultato: 0,
                valutazione: 0,
            }
        };
    },

    watch: {
        gara: function (no) {
            if (!(no&&no.id)) {
                this.dismissModal();
            }
        }
    },

    mounted() {
      this.init();


    },

    beforeRouteEnter (to, from, next) {
      let id = to.params.id;
      if (!from.name) {
        next('calendario');
      } else {
        loadEvent(id)
        .then((result)=>{
          next((vm)=>{
            let gara = result.data.data;
            vm.gara = Object.assign({}, gara);
            vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: gara}});
            vm.$store.dispatch("gare/setGara", {id: id, gara: gara});
            vm.checkCanEdit(id);
            vm.init();
          })
        });
      }
    },

  computed: {
      styleBar: function () {
          const s = [];
          if (this.gara && this.gara.sport_id) {
              s.push(`--color-bar: var(--col-sport-${this.gara.sport_id}`);
          }
          return s.join(";");
      },
    risultato: function () {
      if (this.gara.risultato)
      {
        return this.MSP().sec2hms(this.gara.risultato);
      }
      return 0;
    },
  },
  methods: {
      updateGaraFeedback: function () {
          if (!this.gara) return;
          if (this.feedback.risultato) {
              this.gara = Object.assign(
              {}, this.gara,
              {
                  risultato: this.MSP().hms2sec(this.feedback.risultato)
              }
              );
          }
        },

        risultato2hms: function () {
            if (this.gara && this.gara.risultato) {
                this.feedback.risultato = this.MSP().sec2hms(this.gara.risultato);
            }
        },
        updateFeedback: function () {
            this.MSP().debounce(this.deboucedUpdateFeedback, 300)();
        },

        updateValutazione: function ({valutazione}) {
            this.gara = Object.assign({}, this.gara, {'valutazione': valutazione});
            this.updateFeedback();
        },
        deboucedUpdateFeedback: function () {
            this.updateGaraFeedback();
            let data = this.gara;
            let url = this.api_base_url+"/api/v2/calendario.php?feedback_gara";
            return new Promise((resolve,reject)=>{
                api(url,"POST",data)
                .then((result)=>{
                    if (result.success) {
                        const id = this.gara.calendario_id;
                        this.$store.dispatch("calendario/updateEvent", {id: id, event: {data: this.gara}});
                        this.$store.dispatch("gare/setGara", {id: id, gara: this.gara});
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
            });
            // this.$emit("update", this.gara);
        },
    async deleteItem() {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "Sei sicuro?"
        )
      ) {
        this.deleteConfirm();
        this.dismissModal();
      }
    },
    deleteConfirm() {
        this.$store.dispatch("calendario/deleteEvent", this.gara.calendario_id);
        this.$store.dispatch("gare/deleteGara", this.gara.calendario_id);
    },
    openModal: function () {
      let edit = this.$router.history.current.path.replace("view","edit");
      this.$router.push(edit);
    },
   color: function () {
      return 'var(--col-gara-opaque)';//'var(--col-sport-'+ sport_id +')';
    },
    dismissModal () {
      this.$store.dispatch("calendario/setDialog", false);
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    init: function () {
            this.risultato2hms();
    },
  }

}
</script>

<style lang="scss">
.msp-gara-detail
{
  .img-gara {
    width: 40px;
  }
  .gara-titolo {
    p {
      margin: 0;
      padding:0;
      line-height: 1;
    }
    .gara-specialita {
      font-size: 0.8em;
    }
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;
        color: var(--color-bar);
      }
    }
  }
  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
  textarea {
      resize: none;
      width: 100%;
  }
  .msp-valutazione-mobile {
      display: flex;
      .valutazione-icona {
          margin-right: 20px;
      }
      .valutazione-testo {
          order: 2;
          font-size: 1em;
      }
  }
  .risultato-testo {
      font-size: 1.5em;
      font-weight: bold;
  }
  .feedback {
      display: flex;
  }
}

</style>
