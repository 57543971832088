const attivita = {
        hasZone: function () {
            return this.attivita
            && this.attivita.zone
            && this.attivita.zone.length
        },
        labels: function () {
            if (!(
                this.attivita
                && this.attivita.laps
                && this.attivita.laps.tabella
                )) {
                return [];
            }

            return this.attivita.laps.tabella.keys;
        },
        laps: function () {
            if (!(
                this.attivita
                && this.attivita.laps
                && this.attivita.laps.tabella
                )) {
                return [];
            }

            return this.attivita.laps.tabella.laps;
        },
}

export default attivita;
