  <template>
  <div class="msp-allenamento-detail mx-auto msp-fixed-container" >
    <template v-if="allenamento && allenamento.id">
      <v-app-bar dark  :color=" 'var(--col-sport-'+allenamento.sport_id+')'" class="msp-fixed-header">

            <v-btn icon @click="deleteItem()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <msp-confirm ref="confirmDelete"></msp-confirm>
      <v-spacer></v-spacer>
      <v-toolbar-title>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
    </v-app-bar>

      <v-container fluid class="msp-fixed-content">
        <v-card>
        <v-card-text>
          <v-dialog
                ref="dialog"
                  v-model="editing.data"
                  :return-value.sync="allenamento.data"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                  
                    <v-text-field
                      :value="formatCalendarDay(allenamento.data)"
                      label="Data"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="allenamento.data"
                    scrollable
                          :first-day-of-week="1"
                          locale="it-it"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="editing.data = false"
                    >
                      Annulla
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(allenamento.data); update()"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

          <v-text-field
          label="titolo"
          @blur="editing.titolo = false"
          v-click-outside="()=>{editing.titolo = false}"

          @change="update"
          v-model="allenamento.titolo"
          ></v-text-field>

        <div class="allenamento-dettaglio">
          <div
          v-for="(dettaglio, index) in allenamento.dettagli"
      class="fase"
          :key="index"
          >
            <component
           :is = "currentDettaglio" 
              @update="updateDettaglio" 
              :init-dettaglio="dettaglio"
              :allenamento="allenamento"
              :index="index"
            ></component>
          </div>
        </div>

        </v-card-text>


      </v-card>

      </v-container>
      
      </template>
  </div>


  </template>

  <script>
  import { mapState } from 'vuex';
  import _api from "@mixins/api.js";

  import moment from 'moment';
  import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
  import {_canEdit} from "@mixins/canEdit.js";
  import dettaglioCustomEdit from "@components/dettaglioCustomEdit.vue";
  import dettaglioSportEdit from "@components/dettaglioSportEdit.vue";
  import mspConfirm from '@components/MspConfirm.vue';
  import _constants from "@src/js/msp/constants.js";

  import env from "@mixins/environment.js"


  let loadAllenamento = function (id) {

    let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/calendario.php?mobile&id="+id;

    return new Promise((resolve,reject)=>{
      _api(url, "GET")
      .then((result)=>{
          if (result.success) {
            resolve(result);
          } else {
            resolve(null);
          }
          })
      .catch((error)=>{
          reject(error);
        });
    });
  }

  export default {
    mixins: [
      allenamentoCalcoli,
      env,
    ],
    props: [
      "calendar",
    ],
    components: {
        mspConfirm,
      dettaglioCustomEdit,
      dettaglioSportEdit,
  },
  data: function() {
    return {
      atleta_id:0,
      currentDettaglio: null,
      mode:'',
      editing: {
        data: false,
        titolo: false,
      },
      allenamento: null, 
      sports: [],
      specialita: [],
    };
  },

  watch: {
  },

  async beforeRouteEnter (to, from, next) {
    let id = +to.params.id;
    let userCanEdit = await _canEdit(id);
    if (!userCanEdit) {
      return false;
    }
    loadAllenamento(id)
    .then((result)=>{
      next((vm)=>{
        if (result && result.success) {
          let allenamento = result.data.data;
          vm.allenamento = allenamento;
          if (vm.allenamento.sport_id == _constants.ID_CUSTOM) {
              vm.currentDettaglio = "dettaglio-custom-edit";
          } else {
              vm.currentDettaglio = "dettaglio-sport-edit";
          }
          vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: allenamento}});
          // vm.loadTests();
        }
      })
    });
  },
  mounted() {
    this.init();


  },

  computed: {
    ...mapState({
      initallenamento: state => state.calendario.event,
    }),
  },
  methods: {


        async deleteItem() {
          if (
            await this.$refs.confirmDelete.open(
              "Elimina",
              "Sei sicuro?"
            )
          ) {
            this.deleteConfirm();
            this.dismissModal();
          }
        },
        deleteConfirm() {
          let id = this.allenamento.id;
          this.$store.dispatch("calendario/deleteEvent", id);
        },

    color: function ()
    {
      // const colors = [1,2,3,4,5,10,11];
      //
      // sport_id = (colors.indexOf(sport_id) !== -1) ? sport_id : 10;

      return 'var(--col-allenamento-opaque)';//'var(--col-sport-'+ sport_id +')';
    },
    dismissModal () {
      this.$router.go(-1);
    },

    init: function () {
      this.editing.data = this.calendar;
    },
    updateDettaglio: function (index, dettaglio) {
      this.allenamento.dettagli[index] = dettaglio;

      this.update();
    },
    update: function () {

      let data = this.allenamento;
      let url = this.api_base_url+"/api/v2/calendario.php?evento="+this.allenamento.calendario_id;

      return new Promise((resolve,reject)=>{
        _api(url, "PUT", data)
        .then((result)=>{
            if (result.success) {
              this.$store.dispatch("calendario/updateEvent", {id: +this.allenamento.calendario_id, event: {data: this.allenamento}});
              resolve(result);
            } else {
              resolve(null);
            }
            })
        .catch((error)=>{
            reject(error);
          });
      });
    },

    setParam: function (prop, value) {
      this.allenamento[prop] = value;
    },
    setDate: function (prop, data) { //formato data DD/MM/YYYY
      let a_d = data.split("/");
      let n_d = a_d[2] + "-" + a_d[1] + "-" + a_d[0]
      let format_data = moment(n_d).format('YYYY-MM-DD H:m:s');
      this.setParam(prop, format_data);
    },
    submit: function ()
    {
      this.$emit("submit", this.allenamento);
    }
  }

}
</script>

<style lang="scss">
.msp-allenamento-detail
{
  .img-allenamento {
    width: 40px;
  }
  .allenamento-titolo {
    p {
      margin: 0;
      padding:0;
      line-height: 1;
    }
    .allenamento-specialita {
      font-size: 0.8em;
    }
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
}

</style>
