
<script>
import Chart from 'chart.js/auto'

export default {
    name: 'mspBarChart',
    render: function (createElement) {
      return createElement(
        'div', {
          style: this.styles,
          class: this.cssClasses
        },
        [
          createElement(
            'canvas', {
              attrs: {
                id: this.chartId,
                width: this.width,
                height: this.height
              },
              ref: 'canvas'
            }
          )
        ]
      )
    },

    data() {
        return {
            chart: null,
        }
    },
    props: {
        config: {
            type: Object
        },
     chartId: {
        type: String
      },
      width: {
        default: 400,
        type: Number
      },
      height: {
        default: 400,
        type: Number
      },
      cssClasses: {
        type: String,
        default: ''
      },
      styles: {
        type: Object
      },
    },
    watch: {
        config: function(n,o) {
            if (!o || (n.data !== o.data)) {
                this.update();
            }
        }
    },
    mounted() {
        this.update();
    },
    beforeDestroy () {
      if (this.$data.chart) {
        this.$data.chart.destroy()
      }
    },
    methods: {
        update: function () {
            if (!this.config) {
                return;
            }
            if (this.$data.chart) {
                this.$data.chart.destroy();
            }
            const ctx = this.$refs.canvas;
            this.$data.chart = new Chart(ctx, this.config);
        },
    },
}
</script>
