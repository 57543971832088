<template lang="html">
  <div class="dettaglio-custom-edit">
    <v-textarea
    @onChange="update()"
    @input="debounceUpdate()" 
    v-model="dettaglio.note_attivita"
    ></v-textarea>
  </div>
</template>
<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
export default {
	props: ['init-dettaglio', 'index',  'sport_id', 'allenamento'],

	mixins: [
    allenamentoCalcoli,
	],
  components: {
  },
	data: function () {
		return {
			dettaglio: {...this.initDettaglio},
			tot_attivita: 0,

		}
	},

	mounted: function() {
	},
	methods: {
		textAreaAdjust: function (ev) {
			this.MSP().textAreaAdjust(ev.target);
		},

		updateTextareas: function () {
			this.$nextTick(()=>{

				Array.from(document.querySelectorAll('textarea.auto')).forEach(
					function(el) {
						this.MSP().textAreaAdjust(el);
					}
				);
			});
		},


		debounceUpdate: function () {
      this.MSP().debounce(this.update,500)()
		},
		update: function () {
			this.$emit('update', this.index, {...this.dettaglio });
		},

		remove: function () {
			this.$emit('remove', this.index);
		},

		duplicate: function () {
			this.$emit('duplicate', this.index);
		},

	}
};
</script>

<style lang="scss">
</style>




