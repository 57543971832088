<template>
    <div class="edit-zona">
        <span v-html='formatRisultatoByUmId(filteredValore, um_id, um_descrizione, false)'></span>
    </div>
</template>

<script>

import _funzioni from '../../msp/funzioni.js'
const component = {
    props: ['zona', 'key_valore', 'um_id', 'um_descrizione' ],
    data: function () {
        const data = {

        };
        return data;
    },
    created: function () {


    },
    computed: {
        filteredValore: function () {
            if (!this.zona) return "";
            if (this.key_valore!="media") {
                return this.zona[this.key_valore];
            } else {
                let media = (this.zona.da + this.zona.a)/2;
                return media;
            }
        },
    },


    mounted: function () {

    },
    watch: {
    },
    methods: {
        formatRisultatoByUmId(val, um_id, um_descrizione, hasUm = true) {
            return _funzioni.formatRisultatoByUmId(val, um_id, um_descrizione, hasUm);
        },


    }
}
    export default component;
    </script>

<style lang="scss"></style>
