<template>
    <div class="msp-andature-dettaglio" :class="classComponent">
        <span v-if="+dettaglio.ripetute > 1" class="ripetuta-dettaglio ripetuta">{{dettaglio.ripetute}}&nbsp;x&nbsp;</span>
        <div class="serie-container" >
            <div class="serie" v-for="(serie, i_serie) in dettaglio.serie" :key="serie.id">
                <span v-if="+serie.ripetute > 1" class="ripetuta-serie ripetuta">{{serie.ripetute}}&nbsp;x&nbsp;</span>
                <span v-else class="ripetuta-serie ripetuta">1&nbsp;x&nbsp;</span>
                <span class="andature-container">
                    <span class="prefix" v-if="+serie.ripetute > 1">(</span>
                    <span class="andatura" :class="{'last': i_andatura+1 === serie.andature.length}" v-for="(andatura,i_andatura) in serie.andature" :key="andatura.id">
                        <span class="plus" v-if="i_andatura">+</span>
                        <span class="andatura-value" v-html="formatAndatura(andatura)"></span>
                    </span>
                    <span class="suffix" v-if="+serie.ripetute > 1">)</span>
                </span>
                <span class="divider" v-if="i_serie < dettaglio.serie.length - 1"></span>
            </div>
        </div>
    </div>
</template>

<script>
import ums from "@cached/um.json";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import {methods as methodsAndaturaZonaRange} from '@src/js/vue/mixins/andaturaZonaRange.js'
import {methods as methodsPercorsi} from '@src/js/vue/mixins/percorsi.js'
import _funzioni from '../../../js/msp/funzioni';
const component = {
    props: [
    'um-zone',
    'dettaglio',
    'zone-attive',
    'sport_id',
    'tests',
    ],
  mixins: [
    allenamentoCalcoli,
  ],
  computed: {
        filtered_tests: function () {
            return _funzioni.filterTestsBySportId(this.tests, +this.sport_id);
        },

      classComponent: function () {
          if (+this.dettaglio.ripetute > 1) {
              return "has-ripetute";
          }
      },


  },
  methods: {
    ...methodsAndaturaZonaRange,
    ...methodsPercorsi,

      hasRpm: function (andatura) {
          return +andatura.andatura_rpm_da > 0;
      },
      descrizioneZona: function (andatura, sep = " ", isNumeric = true, sepRpm = " ") {
          if (andatura.des_zona==="REC") return "";
          let zona_id = andatura.andatura_zona_id;
          const defaultDescrizione = "";
          let rpm;
          if (this.hasRpm(andatura)) {
              rpm = `${sepRpm}<em>@${andatura.andatura_rpm_da}-${andatura.andatura_rpm_a} rpm</em>`;
          } else {
              rpm = "";
          }
          if (!isNumeric || !(this.filtered_tests && this.filtered_tests.length)) {
              let descrizione = this.zonaRangeDescrizione(andatura, " ");
              return descrizione + rpm;
          }
          let test;
          if (this.umZone) {
              test = this.filtered_tests.find(el=>el.um_tipo_codice === this.umZone) 
          } else {
              test = this.filtered_tests[0]; // prende il primo test come default
          }
          if (!test) {
              return defaultDescrizione + rpm;
          }
          let zona = this.makeZonaForRange(test,zona_id);
          if (!zona) {
              return defaultDescrizione + rpm;
          }
          let result = this.formatDescrizioneZona(zona, andatura);
          let descrizione;
          if (!result) {
              descrizione = defaultDescrizione + rpm;
          } else {
              descrizione =  sep + result + rpm;
          }

      if (this.andaturaHasPercorso(andatura)) {
          let nomePercorso = this.nomePercorso(andatura.andatura_percorso);
          let percorso = `${sepRpm}<span class="nome-percorso">${nomePercorso}</span>`;
          descrizione += percorso;
      }
      if (andatura.lap) {
          descrizione +=  '<em> Premi tasto LAP</em>';
      }


          return descrizione;
      },

    formatAndatura: function (andatura) {
      let result = "";
      const um = ums.find(el=>+el.id===+andatura.um_id);
      if (!um) return result;

      if (andatura.val_andatura) {
        result += " " + this.MSP().formatRisultatoByUmId(andatura.val_andatura,andatura.um_id, um.descrizione,(um.descrizione.indexOf("mm:ss")===-1));

      }
      if (andatura.des_zona) {
        const zona = (andatura.des_zona==="REC") ? "<span class='recupero'> rec. fermo</span>" : ` in  <strong class='zona'>${andatura.des_zona}</strong>`;
        result += zona;
      }
      result += `<span class='range'>${this.descrizioneZona(andatura)}</span>`;
      return result;
    }

  }
}
export default component;
</script>

<style lang="scss">
.msp-andature-dettaglio {
    padding: 5px 0;
    text-align: left;
    flex-wrap: nowrap;
    .nome-percorso {
        display: inline-block;
        white-space: nowrap;
        padding: 0px 5px;
        align-items: center;
        background: var(--col-grigio-chiarissimo);
        border-radius: 5px;
    }

    .icon-percorso {
        &:before {
            content: var(--icon);
        }
    }

    .range {
        font-size: 0.8em;
        display: inline;
    }
        .recupero {
            font-size: 0.8em;
        }

    .dettaglio-fase {
        width: 100%;
        font-weight: bold;
    }
    .serie-container {
        margin-bottom: 10px;
    }
    .serie {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 5px;

    }
    .ripetuta {
        font-weight: bold;
    }

    
    &.has-ripetute {
        --col-ripetuta: #000;
        --contrast-ripetuta: #fff;
        --space: 5px;
        border-left: 2px solid var(--col-ripetuta);
        padding-left: var(--space);
        padding-top: 0;
    }
    .ripetuta-dettaglio {
        color: var(--contrast-ripetuta);
        background: var(--col-ripetuta);
        margin-top: calc(-1*var(--space)) !important;
        margin-left: calc(-1*var(--space));
        padding-left: var(--space);
        display: inline-block;

    }

    .divider {
        display: none;
    }

    &.mobile {
        .ripetuta-dettaglio {
            flex: 0;
            padding-right: var(--ui-space);

        }
        .serie-container {

        }
        flex-direction: row;
        .serie {
            display: flex;
            gap: var(--ui-space);
        }
        .andatura {
            display: block;
            .andatura-value {
                display: block;
            }

        }
        .andature-container {
            display: block;
        }

        .prefix,
        .suffix,
        .plus {
            display: none !important;
        }

        white-space: nowrap;
    }


}

</style>
