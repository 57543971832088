<template>
    <div class="calcolo-test">


    <div class="calcolo-dati">
    <div class="msp-ui-alert" v-show="!sport_id">
    Seleziona uno sport
    </div>
    <input-test
    v-show = "sport_id"
    :test="test"
    :sport_id = "sport_id"
    :passo = "passo"
    :soglia = "soglia"
    :fixed-data = "fixedData"
    @calcola = "calcolaInput"
    @on_update = "setIsValid"
    >
    </input-test>



    </div>


    </div>

</template>

<script>
import inputTest from './inputTest.vue';
import test from '@mixins/test.js';
export default {
  mixins: [
    test
  ],
  components: {

    inputTest,
  },
  props: ['account', 'sport_id', 'test', 'passo', 'soglia', 'fixedData'],
  watch: {

  },

  data: function () {
    const data = {
      isValid: false,
      saveValues: {},
      debug: false,
    };
    return data;
  },

  computed: { },

  mounted: function () {
    this.load();
  },
  methods: {
      load() { },
      isNuoto(sport_id) {
          return parseInt(sport_id, 10) === 10;
      },
      setIsValid: function (isValid) {
          this.isValid = isValid;
      },

      clearTest: function () {
          this.test = {};
      },

      clear: function () {
          this.setIsValid(false);
          this.clearTest();
      },

      abort: function () { },

      calcolaInput: function(inputs){
          var test = Object.assign(this.test, {inputs: inputs});
          this.calcolaZonePromise(test)
              .then((result) => {
                  if (result.success) {
                      result.data.risultato = this.test.risultato;
                      this.$emit('on_calcola', result.data);
                  }
              })
              .catch((error) => {
                  console.log('error', error);
              });
      },


  }

}
</script>

<style lang="scss">
</style>
