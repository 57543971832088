const MSP = {};
MSP.url = {
  getParam(key) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let param = url.searchParams.get(key);
    return param;
  }
}




MSP._url = {
  params: {},
  getParams() {
    let query=location.search.replace("?", "");
    let params = query.split("&");
    let list = {}
    params.map(el=>{
      let kv = el.split("=");
      list[kv[0]] = kv[1];
    })
    this.params = list;
    return list;
  },

  getParam(key) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
    // this.atleta_id = atleta_id;
    //  return this.getParams()[key];
  },
  setParam(key, value) {
    this.params[key] = value;
  },
  getParamsString() {
    let list = [];
    if (MSP.isEmpty(this.params))
    {
      this.params = this.getParams();
    }
    for (let k in this.params) {
      list.push(k+"="+this.params[k]);
    }
    return list.join("&");
  }
}
export { MSP as default };
