import scaffold from "../../../js/msp/interfaces.js";
import constants from "../../../js/msp/constants.js";
import constructors from "../../../js/msp/constructors.js";
import api from "../../../js/msp/api.js";
import storage from "../../../js/msp/storage.js";
import callbacks from "../../../js/msp/callbacks.js";
import url from "../../../js/msp/url.js";
import color from "../../../js/msp/colors.tinycolor.js";
import funzioni from "../../../js/msp/funzioni.js";

const MSP = Object.assign(
  {
    interfaces: scaffold,
    api: api,
    color: color,
    // funzioni: funzioni,
    // am4charts: am4charts,
    // menu: menu
  },
  constructors,
  constants,
  storage,
  callbacks,
  url,
  funzioni,
);

export default MSP;
