import { render, staticRenderFns } from "./dettaglioSportEdit.vue?vue&type=template&id=3c7d7c55&lang=html&"
import script from "./dettaglioSportEdit.vue?vue&type=script&lang=js&"
export * from "./dettaglioSportEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
