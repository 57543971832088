<template>
    <vue-editor v-if="editorSettings"
        class="msp-editor"
  :value="value"
  @text-change="update"
  @input="input"
  :editorOptions = "editorSettings"
  :placeholder="placeholder"
    ></vue-editor>
</template>
<script>
import { VueEditor, Quill } from "vue2-editor";
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import containers from "./editor/containers.js"
Quill.register("modules/emoji", Emoji);

const BlockEmbed = Quill.import('blots/block/embed');
const Module = Quill.import('core/module');

class DividerBlot extends BlockEmbed {
    static blotName = 'divider';
    static tagName = 'hr';
}




class ToolbarDivider extends Module {
    constructor(quill, options) {
        super(quill, options);

        this.toolbar = quill.getModule('toolbar');
        if (typeof this.toolbar == 'undefined') return;
        var btns = this.toolbar.container.getElementsByClassName('ql-divider');
        if (!btns) return;

        [].slice.call(btns).forEach(function (btn) {
            btn.innerHTML = '<svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="3" x2="15" y1="9" y2="9"></line></svg>';

        });
    }
}

Quill.register(DividerBlot);
Quill.register('modules/divider-toolbar', ToolbarDivider);


export default {
  components: { VueEditor },
  props: {
      'mode': {
          type: String,
          default: 'minimal',
      },
      'value': {
          type: String,
          default: '',
      },
      'placeholder': {
          type: String,
          default: 'Scrivi un messaggio...',
      },
 },
  data: function () {
        return {
            editorSettings: null
        }
  },
  mounted: function () {
      const container = containers[this.mode];
      let toolbarOptions;
      let modules;
      if (container) {
          toolbarOptions = {
              container: container,
              handlers: {
                  'divider': function () {
                      const quill = this.quill;
                      const range = quill.getSelection(true);
                      quill.insertText(range.index, '\n', Quill.sources.USER);
                      quill.insertEmbed(range.index + 1, 'divider', false, Quill.sources.USER);
                      quill.setSelection(range.index + 2, Quill.sources.SILENT);
                  }
              }
          }
          modules= {
              // ...
              toolbar: toolbarOptions,
              "divider-toolbar": true,
              "emoji-toolbar": true,
              "emoji-textarea": false,
              "emoji-shortname": true,
          }
      } else {
          modules = false;
      }

      this.editorSettings = {
          theme: 'snow',
          modules: modules
      }

  },
  methods: {
      input: function (value) {
          this.$emit('input', value);
      },
      update: function (value) {
          this.$emit('update', value);
      }
  },

};
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
.msp-editor {
    text-align: left;
    #emoji-palette {
        top: 0 !important;
        left: 0 !important;
    }
    .ql-editor {
        background: #fff;
    }
    #tab-panel {
        max-height: 120px;
    }
}
</style>

