<template>
<div class="msp-gara-detail mx-auto msp-fixed-container" >
<template v-if="gara">
<v-app-bar :color="color(gara.sport_id)" class="msp-fixed-header" >
<v-btn icon @click="deleteItem()">
<v-icon>mdi-delete</v-icon>
</v-btn>
<msp-confirm ref="confirmDelete"></msp-confirm>
<v-spacer></v-spacer>
<v-toolbar-title>
</v-toolbar-title>

<v-spacer></v-spacer>
<v-btn icon @click="dismissModal()">
<v-icon>mdi-check-bold</v-icon>
</v-btn>
</v-app-bar>
<v-container fluid class="msp-fixed-content">

<v-card
>
<v-card-text>
<v-dialog
ref="dialog"
v-model="editing.data"
:return-value.sync="gara.data"
persistent
width="290px"
>
<template v-slot:activator="{ on, attrs }">

<v-text-field
:value="formatCalendarDay(gara.data)"
label="Data"
prepend-icon="mdi-calendar"
readonly
v-bind="attrs"
v-on="on"
></v-text-field>
</template>
<v-date-picker
v-model="gara.data"
scrollable
:first-day-of-week="1"
locale="it-it"
>
<v-spacer></v-spacer>
<v-btn
text
color="primary"
@click="editing.data = false"
>
Annulla
</v-btn>
<v-btn
text
color="primary"
@click="$refs.dialog.save(gara.data); update()"
>
OK
</v-btn>
</v-date-picker>
</v-dialog>


<div class="gara-gruppi-dati gara-sport">
<label class="gara-nosport" v-if="!(gara && gara.sport_id)"><em>Seleziona uno sport</em>
</label>
<v-select
:items="sports"
item-text="descrizione"
item-value="id"
:value="gara.sport" 
v-model="computedSportId"
label="Sport"
@change="changeSport"
></v-select>
</div>
<v-select
:items="specialita"
item-text="descrizione"
item-value="id"
:value="gara.sport_id" 
v-model="computedSpecialitaId"
label="Specialità"
@change="update"
></v-select>

<v-text-field
label="Località"
prepend-icon="mdi-map-marker-outline"
@change="update"
v-model="gara.luogo"
></v-text-field>


<v-text-field
label="Nome gara"
@change="update"
v-model="gara.descrizione"
></v-text-field>
</v-card-text>
<v-card-text>

<v-slider
v-model="gara.importanza"
:thumb-size="24"
thumb-label="always"
ticks="always"
min="0"
max="3"
label="importanza"
@change="update"

>
<template v-slot:thumb-label="{ value }">
    <img
        :hover="true"
        :src="'/img/coppa.svg#important-'+value"
        class="img-gara"
        >
</template>
</v-slider>
</v-card-text>
</v-card>     
</v-container>


</template>

</div>


</template>

  <script>

  import mspConfirm from '@components/MspConfirm.vue';
  import _api from "@mixins/api.js";
  import {_canEdit} from "@mixins/canEdit.js";
  import moment from 'moment';
  import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
  import env from "@mixins/environment.js"
  import sports from "@cached/sport_gara.json"
  import sportSpecialita from "@cached/sport_specialita.json"

  let loadGara = function (id) {

    let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/calendario.php?id="+id;

    return new Promise((resolve,reject)=>{
        _api(url, "GET")
      .then((result)=>{
          if (result.success) {
            resolve(result);
          } else {
            resolve(null);
          }
        })
        .catch((error)=>{
          reject(error);
        });
    });
    // this.$emit("update", this.allenamento);
  }
  export default {
    mixins: [
      allenamentoCalcoli,
      env,
    ],
    components: {
        mspConfirm,
    },
    props: [
      "calendar",
    ],
    data: function() {
      return {
        atleta_id:0,
        mode:'',
        editing: {
          data: true,
          titolo: false,
        },
        gara: null,
        sports: sports.filter(el=>el.id).sort((a,b)=>(a.posizione>b.posizione)?1:-1),
        sportSpecialita: sportSpecialita,
        specialita: [],
      };
    },

    watch: {

    },

  async beforeRouteEnter (to, from, next) {
    let id = to.params.id;
    let userCanEdit = await _canEdit(id);
    if (!userCanEdit) {
      next(false);
    } else if (!from.name) {
      next('calendario');
    } else {

      loadGara(id)
      .then((result)=>{
        next((vm)=>{
          if (result && result.success) {
            let gara = result.data.data;
            vm.gara = gara;
            vm.init();
            vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: gara}});
          } else {
                vm.dismissModal();
          }
        })
      });
    }
  },

    mounted() {


    },

    computed: {
        computedSportId: {
            get() {
                if (!this.gara.sport_id) return null;
                return this.gara.sport_id.toString();
            },
            set(val) {
                this.gara.sport_id = Number(val)
            }
        },
        computedSpecialitaId: {
            get() {
                if (!this.gara.sport_specialita_id) return null;
                return this.gara.sport_specialita_id.toString();
            },
            set(val) {
                this.gara.sport_specialita_id = Number(val)
            }
        }
    },
    methods: {

        async deleteItem() {
            if (
            await this.$refs.confirmDelete.open(
            "Elimina",
            "Sei sicuro?"
            )
            ) {
                this.deleteConfirm();
                this.dismissModal();
            }
        },

        deleteConfirm() {
            this.$store.dispatch("calendario/deleteEvent", this.gara.calendario_id);
            this.$store.dispatch("gare/deleteGara", this.gara.calendario_id);
        },

        changeSport: function () {
            this.gara = Object.assign({}, this.gara, {'sport_specialita_id': null});
            this.update();
            this.updateSpecialita();
        },

        updateValutazione: function ({valutazione}) {
            this.gara = Object.assign({}, this.gara, {'valutazione': valutazione});
            this.updateFeedback();
        },

        updateSpecialita: function () {
            this.specialita = this.sportSpecialita
            .filter((el)=>(el.sport_id == this.gara.sport_id))
            .sort((a,b)=>(a.posizione>b.posizione)?1:-1);
        },

        risultato2sec: function () {
            if (this.risultato) {
                this.gara = Object.assign(
                {}, this.gara,
                {
                    risultato: this.MSP().hms2sec(this.risultato)
                }
                );
            }
        },

        risultato2hms: function () {
            if (this.gara.risultato) {
                this.risultato = this.MSP().sec2hms(this.gara.risultato);
            }
        },

        updateFeedback: function () {
            this.MSP().debounce(this.deboucedUpdateFeedback, 300)();
        },

        deboucedUpdateFeedback: function () {
            this.risultato2sec();
            let data = this.gara;
            let url = this.api_base_url+"/api/v2/calendario.php?feedback_gara";
            return new Promise((resolve,reject)=>{
                _api(url, "POST", data)
                .then((result)=>{
                    if (result.success) {
                        const id = this.gara.calendario_id;
                        this.$store.dispatch("calendario/updateEvent", {id: id, event: {data: this.gara}});
                        this.$store.dispatch("gare/setGara", {id: id, gara: this.gara});
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                });
            });
            // this.$emit("update", this.gara);
        },

        setParam: function (prop, value) {
            this.gara[prop] = value;
        },

        setDate: function (prop, data) { //formato data DD/MM/YYYY
            let a_d = data.split("/");
            let n_d = a_d[2] + "-" + a_d[1] + "-" + a_d[0]
            let format_data = moment(n_d).format('YYYY-MM-DD H:m:s');
            this.setParam(prop, format_data);
            this.update();
        },

        submit: function () {
            this.$emit("submit", this.gara);
        },

        color: function () {
            // const colors = [1,2,3,4,5,10,11];
            //
            // sport_id = (colors.indexOf(sport_id) !== -1) ? sport_id : 10;

            return 'var(--col-gara-opaque)';//'var(--col-sport-'+ sport_id +')';
        },

        dismissModal () {
            let debug_from = true;
            if(debug_from) {
                this.$router.go(-1);
            } else {
                this.$store.dispatch("calendario/setDialog", false);
            }
        },

        init: function () {
            this.editing.data = this.calendar;
            this.updateSpecialita();
            this.risultato2hms();
        },


        update: function () {
            this.risultato2sec();
            let data = this.gara;
            let url = this.api_base_url+"/api/v2/calendario.php?evento="+this.gara.calendario_id;
            return new Promise((resolve,reject)=>{
                _api(url, "PUT", data)
                    .then((result)=>{
                        if (result.success) {
                            this.$store.dispatch("calendario/updateEvent", {id: this.gara.calendario_id, event: {data: this.gara}});
                            this.$store.dispatch("gare/setGara", {id: this.gara.calendario_id, gara: this.gara});
                            resolve(result);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    });
            });
        },
    }

}
</script>

<style lang="scss">
 .card-feedback  {
      .v-card__title {
        font-weight:bold;
        font-size: 1em;
        text-align: center;
        justify-content: center;
      }
  }
.msp-gara-detail {
  .img-gara {
    width: 40px;
  }
  .gara-titolo {
    p {
      margin: 0;
      padding:0;
      line-height: 1;
    }
    .gara-specialita {
      font-size: 0.8em;
    }
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
}

</style>
