<template>
  <div>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    name: 'mspLineChart',
    data() {
        return {
            chart: null,
        }
    },
    props: [
        'config',
    ],
    watch: {
        config: function(n,o) {
            if (!o || (n.data !== o.data)) {
                this.update();
            }
        }
    },
    mounted() {
        this.update();
    },
    beforeDestroy () {
      if (this.chart) {
        this.chart.destroy()
      }
    },
    methods: {
        update: function () {
            if (!this.config) {
                return;
            }

            if (this.chart) {
                this.chart.destroy();
            }

            const ctx = this.$refs.chart;
            this.chart = new Chart(ctx, this.config);
        },
    },
}
</script>

