<template>
    <div class="msp-impegno-detail mx-auto msp-fixed-container" >
    <template v-if="impegno">

      <v-app-bar  :color="color()" class="msp-fixed-header" >

            <v-btn icon @click="deleteItem()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <msp-confirm ref="confirmDelete"></msp-confirm>
      <v-spacer></v-spacer>
      <v-toolbar-title>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
      </v-app-bar>
      <v-container fluid class="msp-fixed-content">

        <v-card
        >
        <v-card-text>
        <v-dialog
        ref="dialog_data"
        v-model="editing.data"
        :return-value.sync="impegnoData"
        persistent
        width="290px"
        >
        <template v-slot:activator="{ on, attrs }">
        
          <v-text-field
            :value="formatCalendarDay(impegno.data)"
            label="Data inizio"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="impegno.data"
          scrollable
          locale="it-it"
          :first-day-of-week="1"
          :title-date-format="titleDateFormat"
        >
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="editing.data = false"
          >
            Annulla
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog_data.save(impegno.data); update()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <v-dialog
      ref="dialog_data_fine"
        v-model="editing.data_fine"
        :return-value.sync="impegnoDataFine"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
        
          <v-text-field
            :value="formatCalendarDay(impegno.data_fine)"
            label="Data fine"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="impegno.data_fine"
          scrollable
          locale="it-it"
          :first-day-of-week="1"
          :title-date-format="titleDateFormat"
        >
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="editing.data_fine = false"
          >
            Annulla
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog_data_fine.save(impegno.data_fine); update()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <v-row>
      <v-col
      cols=12
      sm=6
      >
      <i :class="'msp-icon-impegno-'+impegno.impegno_tipo_id"></i>
      <v-select
      :items="tipi"
      item-text="descrizione"
      item-value="id"
      :value="impegno.impegno_tipo_id" 
      v-model="impegno.impegno_tipo_id"
      label="Tipo di impegno"
      @change="update"
      ></v-select>
      </v-col>
      <v-col
      cols=12
      sm=6
      >
      <v-textarea @input="update" class="" v-model="impegno.descrizione" placeholder="Aggiungi le informazioni relative all'impegno utili per l'allenatore"></v-textarea>
      </v-col>
      </v-row>
      </v-card-text>
      </v-card>

      </v-container>
      

    </template>

    </div>


  </template>

    <script>

import mspConfirm from '@components/MspConfirm.vue';
import {_canEdit} from "@mixins/canEdit.js";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import env from "@mixins/environment.js"

import loadImpegno from "@mixins/loadEvent.js"
import _api from "@mixins/api.js";

export default {
    mixins: [
        allenamentoCalcoli,
        env,
    ],
    components: {
        mspConfirm,
    },
    props: [
        "calendar",
    ],
    data: function() {
        return {
            tipi: [],
            mode:'',
            impegnoData: null,
            impegnoDataFine: null,
            editing: {
                data: true,
                data_fine: false,
                titolo: false,
            },
            impegno: null,
        };
    },

    watch: {
        impegnoData: function (val) {
            this.impegno.data = val;
            if (val > this.impegno.data_fine) this.impegno.data_fine = this.impegno.data;
            this.update();
        },
        impegnoDataFine: function (val) {
            this.impegno.data_fine = val;
            if (val < this.impegno.data) this.impegno.data = this.impegno.data_fine;
            this.update();
        },
    },

    async beforeRouteEnter (to, from, next) {
        let id = to.params.id;
        let userCanEdit = await _canEdit(id);
        if (!userCanEdit) {
            next(false);
        } else if (!from.name) {
            next('calendario');
        } else {
            loadImpegno(id)
                .then((result)=>{
                    next((vm)=>{
                        if (result && result.success) {
                            let impegno = result.data.data;
                            vm.impegno = impegno;
                            vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: impegno}});
                            vm.init();
                        }
                    })
                });
        }
    },

    mounted() {
    },

    computed: {
    },
    methods: {

        async deleteItem() {
            if (
                await this.$refs.confirmDelete.open(
                    "Elimina",
                    "Sei sicuro?"
                )
            ) {
                this.deleteConfirm();
                this.dismissModal();
            }
        },
        deleteConfirm() {
            let id = this.impegno.id;
            this.$store.dispatch("calendario/deleteEvent", id);
        },

        loadImpegnoTipo: function () {
            let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/impegno_tipo.php";
            _api(url, "GET")
                .then((result)=>{
                    if (result && result.success) {
                        this.tipi = result.data;
                    } else {
                        this.tipi = [];
                    }
                });
        },
        updateDates: function (na) {
            let data = null;
            let data_fine = null;
            if (na.length > 0) {
                data = na[0];
            }
            if (na.length > 1) {
                data_fine = na[1];
            }
            if (data > data_fine) {
                [data, data_fine] = [data_fine, data];
            }
            this.impegno = Object.assign(this.impegno,
                {
                    data: data,
                    data_fine: data_fine,
                }
            );
        },
        titleDateFormat: function (date) {
            if (!date) {
                return "-";
            }
            return this.formatCalendarDay(date);

        },


        setParam: function (prop, value) {
            this.impegno[prop] = value;
        },

        submit: function ()
        {
            this.$emit("submit", this.impegno);
        },
        color: function () {
            return 'var(--col-grigio-chiarissimo)';
        },
        updateData: function (/*ev*/) { },
        dismissModal () {
            let debug_from = true;
            if(debug_from) {
                this.$router.go(-1);
            } else {
                this.$store.dispatch("calendario/setDialog", false);
            }
        },
        init: function () {
            this.editing.data = this.calendar;
            this.loadImpegnoTipo();
        },
        updateImpegnoTipo: function () {
            if (this.tipi && this.impegno.impegno_tipo_id) {
                let tipo = this.tipi.find(el=>+el.id===+this.impegno.tipo_id);
                if (tipo) {
                    this.impegno.impegno_tipo_descrizione = tipo.descrizione;
                }
            }
        },

        update: function () {
            this.updateImpegnoTipo();
            let data = this.impegno;
            let url = this.api_base_url+"/api/v2/calendario.php?evento="+this.impegno.id;

            return new Promise((resolve,reject)=>{
                _api(url, "PUT", data)
                    .then((result)=>{
                        if (result.success) {
                            this.$store.dispatch("calendario/updateEvent", {id: this.impegno.calendario_id, event: {data: this.impegno}});
                            resolve(result);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    });
            });
        },
    }

}
    </script>

<style lang="scss">
.msp-impegno-detail {
  .impegno-titolo {
    p {
      margin: 0;
      padding:0;
      line-height: 1;
    }
    .impegno-specialita {
      font-size: 0.8em;
    }
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
}

</style>
