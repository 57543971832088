<template lang="html">

  <div
  class="lista-attivita"
  >
  <div class="lista-attivita__content">
    <div
    class="loading-container"
    :class="{
      'loading': loading
      }">

      <div class="loading-pending">
        <p>loading...</p>
      </div>

      <div class="loading-complete">
        <p v-if="activities.length">Seleziona fra queste attività.</p>
        <p v-else>Non ci sono attività per questo periodo.</p>
        <v-list>
<template
v-for="(activity, index) in activities"
>

          <v-list-item
:class="classActivity(activity)"
:key="activity.id"
          @click="select(activity)"
          >
          <v-list-item-content>
            <v-list-item-subtitle >
              <strong>{{formatData(activity.dataAllenamento,"/")}}</strong>
            </v-list-item-subtitle>
            <v-list-item-title >{{activity.titoloAllenamento || activity.sport}}</v-list-item-title>
            <v-list-item-subtitle >
              {{activity.distanza}} km - {{activity.durata && MSP().sec2hms(activity.durata)}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
<v-divider
v-if="index < activities.length -1"
:key="'divider-'+activity.id"

></v-divider>
      </template>

      </v-list>

    </div>
  </div>


</div><!--content-->
</div>

</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import _calendario from "@src/js/vue/mixins/calendario.js";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";

const component = {
  mixins: [
    allenamentoCalcoli,
    env,

  ],
  props: [
    'data',
    'attivita_id',
    'calendario_id',
    'sport_id',
  ],

  data: function() {
    let data = {
      loading: false,
      activities: [],
      _id: 0
    }
    return data;
  },
  mounted () {

    this.init();
  },
  computed: {

  },
  watch: {
    attivita_id: function ()
    {
      this.update();
    },

  },
  methods: {
    update: function ()
    {
      this.init();
    },
    classActivity: function (activity)
    {
      let a_classes = [];
      if (activity && activity.id === this.attivita_id)
      {
        a_classes.push("active");
      }
      return a_classes.join(" ");
    },
    select: function (activity)
    {
      this.$emit("select", activity);
    },
    loadAttivita: function ()
    {
      if (!this.data)
      {
        console.warn("Manca la data");
        return Promise.resolve({success: false});
      }
      let da = _calendario.addDays(this.data, -10);
      let a = _calendario.addDays(this.data, 10);
      let data = {
        liberi: 1,
        sport_id: this.sport_id,
        da: da,
        a: a,
      }
      let params = this.MSP().api.URLparams(data);
      let url = this.api_base_url+"/api/v2/attivita.php?"+params;

      return new Promise((resolve,reject)=>{
          _api(url, "GET")
              .then((result)=>{
                  resolve(result);
              })
              .catch((error)=>{
                  reject(error);
              });
      });
    },
    init: function ()
    {

      this.loadAttivita()
      .then(
        (result)=>{
          if(result.success)
          {
            this.activities = result.data;
          }
        }
      )


    }
  }

}

export default component;
</script>

<style lang="scss">

.loading-container {
  .loading-pending {
    display: none;
    padding: 1em;
    background: var(--col-grigio-chiaro);
    text-align: center;
  }
  .loading-complete {
    display: block;
  }
  &.loading {
    .loading-complete {
      display: none;
    }
    .loading-pending {
      display: block;
    }
  }
}
.lista-attivita {
  .lista-attivita-allenamento__sport i {
    font-size: 2rem;
  }
  &__allenamento {
    &.deactive {
      td {
        background: var(--col-grigio-chiarissimo);
        color: var(--col-grigio-medio);
        cursor: not-allowed;
      }
    }
    &.active {
      td {
        background: var(--col-ui-evidenza) !important;
        color: #fff;
      }
    }
  }
}

</style>
