<template>
    <div class="msp-valutazione-allenamento-mobile">
    <msp-valutazione @update="select" :readonly="readonly" :init-value="initValue"></msp-valutazione></div>
</template>

<script>
import _api from "@mixins/api.js";
import valori from "@cached/valutazione_allenamento.json"
import mspValutazione from "./MspValutazione.vue"
const component = {
    components: {
        mspValutazione,
    },
    props: [
        'calendario-id',
        'init-value',
        'readonly',

    ],
    computed: {
        valutazione: function () {
            return this.findValutazione(this.selected);
        },
    },
    mounted: function () {
        this.initSelected();
    },
    data() {
        return {
            valori: valori,
            selected: -1,
            slider: 2, 
            placeholder: valori[2],
        }
    },
    watch: {
        initValue: function (nv,ov) {
            if (nv !== ov) {
                this.initSelected();
            }
        },
        slider: function (nv) {
            this.selected = nv; 
        },
    },
    methods: {
        initSelected: function () {
            if (this.initValue === null) {
                this.selected = -1;
            }
            if (!(this.valori && this.valori.length)) {
                this.selected = -1;
            }
            this.selected = this.valori.findIndex((el)=>el.id==this.initValue);
        },
        findValutazione: function (index) {
            if (!(this.valori && this.valori.length)) {
                return false;
            }

            return this.valori[index];
        },
        classItem: function (index) {
            if (this.selected === index) {
                return "selected";
            }
        },
        select: function ({valutazione}) {
            this.selected = valutazione;
            let data = {
                calendario_id: this.calendarioId,
                valutazione: valutazione,
            };

            this.loading = true;
            const url =  process.env.VUE_APP_API_BASE_URL + "/api/v2/feedback.php";
            _api(url, "POST", data)
                .then((result)=>{
                    if (result.status !== 201) return;
                    if (result.data.success) {
                        this.$emit("update", data);
                    }
                });
        }
    },
}
export default component;
</script>

<style lang="scss">
.msp-valutazione-allenamento-mobile {
    --color: #ccc;
    text-align: center;
    padding: 0 20px;
    .valutazione-dettaglio {
        display: grid;
        grid-template-columns: 1fr auto;
        align-content: center;
        img {
            height: 40px;
            align-self: center;
        }
        &.placeholder {
            opacity: 0.2;
            img {
                filter: grayscale(1);
            }
        }

    }
        .msp-valutazione-allenamento-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-content: center;
            align-content: center;
            align-items: center;
            margin: auto;
            text-align: center;
            justify-content: center;
        }
        
        .msp-valutazione-allenamento-item {
            --border-color: #fff;
            font-weight: bold;
            letter-spacing: 0.0333em;
            text-transform: uppercase;
            user-select: none;
            text-align: left;
            font-size: 1.5em;
            line-height: 1;
        }

        .valutazione-icona {
          background: var(--color);
          width: 1em;
          height: 1em;
          font-size: 40px;
          display: grid;
          justify-content: center;
          align-content: center;
          border-radius: 2em;
        }
    }
</style>
