<template lang="html">
    <div
    :id="id"
    class="rpe"
    :class="{'sola-lettura': readonly}"
    >
    <div class="rpe-items">
    <div
    v-for="item in rpe"
    :key="item.rpe"
    @click="select(item)"
    @mouseover="setTitle(item)"
    @mouseout="setTitle()"

    :class="{'selected': (+item.rpe===+selected)}"
    class="rpe-item"
    :style="'--col:'+item.colore+'; --text-col:'+item.contrasto+'; --rpe:'+item.rpe"
    >
    <div class="rpe-value">{{item.rpe}}</div>
    </div>
    </div>
    <div class="rpe-title">{{currentTitolo}}</div>
    </div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import axios from 'axios'
import env from "@mixins/environment.js"

  export default {
    mixins: [
      allenamentoCalcoli,
      env,
    ],
  props: [
    'loading',
    'id',
    'readonly',
    'selected',
  ],

  data: function() {
    let data = {
      rpe: [],
      titolo: '',
    }
    return data;
  },
  mounted () {
    this.loadRpe();
    this.init();
  },
  computed: {
    current()
    {
      return this.rpe.find(el=>+el.rpe===+this.selected);
    },
    currentTitolo()
    {
      let i = this.titolo && this.titolo.rpe || this.selected;
      if (!i)
      {
        return ""
      }
      let rpe = null;
      this.rpe.filter(el=>el.titolo.length).forEach((el)=>{
        if (+el.rpe <= +i)
        {
          rpe = el;
        }
      });
      if (rpe)
      {
        return rpe.titolo;
      }


      return this.titolo.titolo ||  "";
    }

  },
  watch: {
  },
  methods: {
    loadRpe() {
      axios.get(this.api_base_url + "/api/v2/rpe.php")
      .then((response)=>{
        this.rpe = response.data.data;
      })
    },
    update() {
      this.init();
    },
    setTitle: function (rpe = null)
    {
    if (!rpe)
    {
        this.titolo = null;
    }
    else
    {
      this.titolo = rpe;
    }
    },
    select: function (rpe)
    {
      if (this.readonly)
      {
        return false;
      }
      this.$emit("select", rpe);
    },
    formatData: function (date) {
      let d = new Date(date);
      return d.toLocaleDateString('it');
    },

    formatSport(s) {
      return s.replace("_", " ");
    },
    formatIconSport(s) {

      return 'msp-icon-sport-' + s;
    },


    init: function ()
    {

    }
  }

}
</script>

<style lang="scss">
.rpe {
  --height: 30px;
  --rpe: 0;
  --col: #ccc;
  --text-col: #000;
  --border-color: var(--col-grigio-chiaro);
  --border-color-hover: #000;
  --box-shadow: 0 0 10px rgba(0,0,0,0.5);
  --pointer: pointer;
  .rpe-items {
    display: flex;
  }
  .rpe-item {
    border-radius: 30%;
    width: var(--height);
    height: var(--height);
    margin: 0 2px;
    display: grid;
     align-items: center;
    color: #000;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: var(--col);
    background: #fff;

    &.selected {
        border-color: var(--border-color);
        background: var(--col);
        color: var(--text-col);
    }

    &:hover {
      cursor: var(--pointer);
      --border-color: var(--border-color-hover);
      box-shadow: var(--box-shadow);
    }
  }

  &.sola-lettura {
    border: none;
    --pointer: default;
    --border-color-hover: var(--col-grigio-chiaro);
    --box-shadow: none;
  }


  .rpe-value {
    display: block;
  }

  .rpe-title {
    height: 2em;
    text-align: center;
    display: grid;
    align-items: center;
    font-weight: bold;
  }


}

</style>
