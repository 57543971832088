import _api from "./api.js";

const _canEdit = function (calendario_id) {
  let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/calendario.php?can_edit="+calendario_id;
    return new Promise((resolve)=>{
        _api(url, "GET")
            .then((result)=>{
                if (result.success) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(()=>{
                resolve(false);
            })
    });
}

const _canViewAutovalutazione = function (id) {
  let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/autovalutazione_atleta.php?can_view="+id;
    return new Promise((resolve)=>{
        _api(url, "GET")
            .then((result)=>{
                if (result.success) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(()=>{
                resolve(false);
            })
    });
}

const _canViewPeso = function (id) {
  let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/peso.php?can_view="+id;
    return new Promise((resolve)=>{
        _api(url, "GET")
            .then((result)=>{
                if (result.success) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(()=>{
                resolve(false);
            })
    });

}
const _canViewAttivita = function (attivita_id) {
  let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/attivita.php?can_view="+attivita_id;
    return new Promise((resolve)=>{
        _api(url, "GET")
            .then((result)=>{
                if (result.success) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(()=>{
                resolve(false);
            })
    });

}

const canEdit = {
  data: function () {
    return {
      canEdit: false,
      canViewAttivita: false,
    };
  },
    methods: {
      checkCanViewAttivita: async function (attivita_id) {
        let canViewAttivita = await _canViewAttivita(attivita_id);
        this.canViewAttivita = canViewAttivita;
        return canViewAttivita;
    },
      checkCanEdit: async function (calendario_id) {
        let canEdit = await _canEdit(calendario_id);
        this.canEdit = canEdit;
        return canEdit;
    },
  }
}

export {
    canEdit as default,
    _canEdit,
    _canViewAttivita,
    _canViewAutovalutazione,
    _canViewPeso,
}
